@if(selectStage){
  <app-sidebar [show]="showUploadsList"
               [showClose]="true"
               (onSave)="showUploadsList=false"
               [height]="100"
               [width]="100"
               [ext]="'%'"
               [zIndex]="100000"
               [position]="'top'"

  >
<app-album
  *ngIf="(selectStage.images.length>0 && showListModal && showUploadsList) "
  [showUploadsList]="showUploadsList"
  [models]="selectStage.images"
  (onSelect)="clickItem($event);"
  [showSelect]="true"
  (onClose)="showUploadsList =false"
  (onDelete)="updateStage()"
  (onSave)="updateStage()"
></app-album>
  </app-sidebar>


<div *ngIf="(selectStage.images.length>0 && showListHorisont) "
     [style.width.px]="windowWidth()/2"
     class="flex overflow-x-auto py-4 space-x-4">
  <ng-container *ngFor="let  uploadItem  of selectStage.images;">
    <div class="rounded-md w-[75px] h-[75px] shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse ">
      <img [src]="uploadItem.srcPreview" alt="upload_image" class="handle w-16 h-16 object-cover rounded cursor-pointer"
           (click)="clickItem(uploadItem)"
      >
      <button *ngIf="uploadItem.check"
              class="text-sm absolute top-0 left-2 bg-green-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
      >
        <i [class]="favoriteIcon"></i>
      </button>
      <button
        class="text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
        (click)="deleteFromArray(uploadItem,selectStage.images);updateStage()">
        <i [class]="deleteIcon"></i>
      </button>
    </div>
  </ng-container>
</div>
<div *ngIf="uploadSpinner "
     class="bg-gradient-to-r from-amber-500 to-red-500 hover:from-amber-600 hover:to-red-600 text-white font-bold py-6 px-12 rounded-full shadow-lg flex flex-col items-center space-y-2 transition duration-300 ease-in-out transform hover:scale-105 animate-pulse relative">

  <i *ngIf="showCounter && this.index !==0"
     [class]="'fa-solid fa-ellipsis'"
     pBadge
     [value]="this.index"
  ></i>
  <span>loading...</span>
</div>

<label *ngIf="!uploadSpinner" for="upload" class="relative cursor-pointer">
  <div class=" z-20 absolute top-3 -right-3 inline-block transition-transform transform hover:scale-110">

    <button *ngIf="(selectStage.images.length>0 && showListModal)"
            (click)="showList();"
            class="   inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 border border-transparent rounded-full hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"

    >
     <!-- <i [class]="theme.icon.list"
         pBadge
         [value]="this.selectStage.images.length"
      ></i>-->
    </button>
  </div>

  <div *ngIf="showUpload"
    class=" z-1 bg-gradient-to-r from-amber-500 to-red-500 hover:from-amber-600 hover:to-red-600 text-white font-bold py-6 px-12 rounded-full shadow-lg flex flex-col items-center space-y-2 transition duration-300 ease-in-out transform hover:scale-105 animate-pulse relative">
        <span class="text-xs">
<!--          <img [src]="theme.iconSrc.download" alt="Upload icon" class="h-8 w-8">-->

      <i class="fas fa-upload"></i>
          Upload file  </span>
    <input id="upload"
           type="file"
           ng2FileSelect
           [uploader]="uploader"
           (onFileSelected)=" uploadPhoto();"
           [multiple]="multiple"
           class="hidden"
    />
  </div>
</label>
}
<!--<div class="example-1"     (click)="onClickUpload.emit()">

  <label class="label">


    <ng-container *ngIf="uploadSpinner">
      <i *ngIf="showCounter && this.index !==0"
         [class]="'fa-solid fa-ellipsis'"
         pBadge
         [value]="this.index"
      ></i>
      <span>Loading</span>
    </ng-container>

    <ng-container *ngIf="!uploadSpinner">
      <i class="fas fa-upload"></i>
&lt;!&ndash;      <span *ngIf="showLabel">Upload</span>&ndash;&gt;
      &lt;!&ndash;<input type="file"
             ng2FileSelect
             [uploader]="uploader"
             (onFileSelected)=" uploadPhoto();"
             [multiple]="multiple"
      />&ndash;&gt;
    </ng-container>
  </label>
</div>-->
<!--<div *ngIf="(showUploadDropZone === true)" ng2FileDrop
     style="width: 200px;
    height: 50px;
    display: block;
    border: 5px dotted #4e4444;"
     [style.border-color]="hasBaseDropZoneOver && 'red'"
     [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
     (fileOver)="fileOverBase($event)"
     [uploader]="uploader"
     (onFileDrop)="uploadPhoto($event);"
     class="well my-drop-zone">
   DropZone
</div>-->
