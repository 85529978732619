  <div class=" text-white min-h-[150px] p-1 rounded-lg bg-gray-800 shadow-md transition duration-300 ease-in-out transform hover:scale-105">
    <div class="p-1 rounded-lg bg-gray-800 shadow-md transition duration-300 ease-in-out transform hover:scale-105">
      <button
              class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
        <img [src]="imageSrc? imageSrc: 'assets/background_logo_small.png'"
             class="w-16 h-16 inline rounded-full"
             alt="{{imageSrc}}"
        >
        <img *ngIf="card && card.srcIcon"
             [src]="card.srcIcon"
             class=" absolute top-0 right-0 w-8 h-8 inline rounded-full"
             alt="{{card.srcIcon}}"
        >
      </button>
      <button
        class=" focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
        <p *ngIf="card && card.desc"

             class=" text-[10px] absolute top-0  w-full truncate right-0 inline rounded-full"
        >
          {{card.desc}}
        </p>
        {{name? name: 'NO NAME'}}
      </button>
  </div>
    <ng-container  >
    <button *ngIf="showEdit"
      (click)="onSelect.emit(card)"
            class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
      <i [class]="editIcon" class="text-gray-300 hover:text-white"></i>
    </button>
    <button  *ngIf="showDublicate"
      (click)="addCloneModel(card,cards); onSave.emit()"
            class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
      <i [class]="addIcon" class="text-gray-300 hover:text-white"></i>
    </button>
    <button *ngIf="showMove"
      class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
      <i [class]="'fas fa-arrows-alt'"></i>
    </button>
    </ng-container>
  </div>
<!--<button-->
<!--  class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">-->
  <!--          <i *ngIf="cardItem.free === true" [class]="theme.icon.gift" (click)="cardItem.free = false; onSave.emit()"></i>-->
  <!--          <i *ngIf="cardItem.free === false" [class]="theme.icon.premium" (click)="cardItem.free = true;onSave.emit()"></i>-->
<!--</button>-->
<!--<button-->
<!--  class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">-->
  <!--          <i *ngIf="cardItem.sw === true" [class]="theme.icon.eyeClose" (click)="cardItem.sw = false;"></i>-->
  <!--          <i *ngIf="cardItem.sw === false" [class]="theme.icon.eyeOpen" (click)="cardItem.sw = true;"></i>-->
<!--</button>-->
<!--<button (click)="addCloneModel(cardItem,cards); onSave.emit()"
        class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
  <i [class]="addIcon" class="text-gray-300 hover:text-white"></i>
</button>-->

<!-- <button (click)="onSelect.emit(serviceSite);"
         class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
   <i [class]="editIcon" class="text-gray-300 hover:text-white"></i>
 </button>-->
<!--   <button *ngIf="cd.enabledDelete" (click)="delete(cardItem, cards);"
           class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
     <i [class]="theme.icon.delete" class="text-gray-300 hover:text-white"></i>
   </button>-->
<!--<button
  class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
  <i [class]="'fas fa-arrows-alt'"></i>
</button>-->
<!--     <textarea
         style="padding: 2px!important;font-size: 16px !important; "
         pInputTextarea
         [placeholder]="'name'"
         [(ngModel)]="cardItem.name"
         [style.min-height.px]="50"
         [style.height.px]="50"
         [style.min-width.px]="250"
         (ngModelChange)="this.onSave.emit();"
       ></textarea>-->

