<button
        class="w-16 h-16 bg-zinc-200 shadow-md hover:bg-[#4CAF00] transition-colors rounded-full flex items-center justify-center m-2"
        [disabled]="disabled"

        (click)="onClick.emit()"
>
  <img [src]="srcIcon"
       [alt]="name"
       class="w-16 h-16 text-[#AFAFAF]"
  >
</button>
