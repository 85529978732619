import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
// @ts-ignore
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  LoadedImage,
  ImageTransform,
  Dimensions,
  base64ToFile,
  ImageCropperModule
} from 'ngx-image-cropper';
import {NgFor, NgIf} from "@angular/common";
import { CropRigionServices } from 'refactoring-code/services/sort/crop-rigion.services';
import {moveToCenter} from "../../traits/window-tools";
import {DomSanitizer} from "@angular/platform-browser";
// import { Dimensions, ImageCroppedEvent, ImageTransform } from './image-cropper/interfaces/index';
// import {base64ToFile} from './image-cropper/utils/blob.utils';

@Component({
  selector: 'app-crop-rigion',
  standalone:true,
  imports:[NgIf,NgFor,ImageCropperModule],
  templateUrl: './crop-rigion.component.html',
  styleUrls: ['./crop-rigion.component.scss'],
})
export class CropRigionComponent implements OnInit {
  // @ts-ignore
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  public coordinates: any = {x1: 161.5, y1: 34, x2: 360.5, y2: 233};

  // @ts-ignore
  @Input() showCropsItems: any =false;
  @Input() showAspectRatio: any =false;
  @Input() model: any;
  @Input() t: any = 'img';
  @Input() type: any = 'blob';
  @Input() height: any = 300;
  @Input() width: any = 300;
  @Input() cropZoneWidth: any = 300;
  @Input() cropZoneHeight: any = 300;
  @Input() cropZoneX: any = 50;
  @Input() cropZoneY: any = 50;
  @Input() src: any  ='http://server.artas.org.ua/Fotozona/1.png';

  @Input() float: any = false;
  @Input() showDownload: any = false;
  @Input() aspectRation: any = false;
  @Input() showRotate: any = false;
  @Input() showFlip: any = false;
  @Input() showZoom: any = false;
  @Input() showReset: any = false;
  @Input() showAdd: any = true;
  @Input() showClose: any = true;
  @Input() showPressetsCrop: any = true;
  @Input() opacity: any = 1;
  public showContent: any = true;
  @Input() folder: any = '';
  @Input() crops: any = [];
  colors: any = [];
  @Input() selectCrop: any;

  @Output() oPhoto = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() eventEmit = new EventEmitter();
  @Output() crop = new EventEmitter();
  @Output() onColors = new EventEmitter();
  @Output() onCrop = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();


  // @ts-ignore
  public uploader: FileUploader;
  public cropsParams:any  = [
    {name:'100X100', icon:'fa-solid fa-vector-square',height:100,width:100,x:0,y:0},
    // {name:'100X50', icon:'fa-solid fa-image-portrait',height:100,width:50,x:0,y:0},
    // {name:'50X100', icon:'fa-solid fa-panorama',height:50,width:100,x:0,y:0}
  ];
  constructor(private sanitizer: DomSanitizer,public  cropRigion: CropRigionServices,

  ) {
  }

  public imageChangedEvent: any;
  public croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  // }
  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  imageCropped(event: ImageCroppedEvent) {
    // let base64 =  this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    let base64 =  event.base64
    // console.log(base64)
    // let base64 = event.base64;
    this.croppedImage = base64;
    this.onCrop.emit(base64);
    // this.cd.img.color(base64, (colors: any) => {
    //   this.colors = colors.all;
    //   this.onColors.emit(colors.all);
    // });

    // @ts-ignore
    // console.log('event', base64ToFile(event.base64));
    /*let obj = {
      // @ts-ignore
      name: file.name.substr(0, file.name.lastIndexOf('.')),
      // @ts-ignore
      ext: file.name.substr(file.name.indexOf('.')).toLowerCase(),
      proportion: '',
      size: {width: 0, height: 0},
      // @ts-ignore
      bt: file.size,
      // @ts-ignore
      kb: this.cd.img.bytesToSize(file.size),
      src: ''
    };*/

    // this. crop.emit({src:this.croppedImage})
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }


  loadImageFailed() {
    // show message
  }

  setFrameSize( cropZoneHeight:any = 50,cropZoneWidth: any = 50,cropZoneX:any  = 0, cropZoneY:any = 0) {
    setTimeout(() => {
      let center = moveToCenter(this.height,this.width,cropZoneHeight,cropZoneWidth);
      // console.log(this.height, this.width,'this.height','this.width');
      // console.log(center, 'center');
      cropZoneX  =center.x;
        cropZoneY = center.y;
      this.coordinates = {
        x1: cropZoneHeight,// 161.5,
        y1: cropZoneWidth ,// 34,
        x2: cropZoneX,
        y2: cropZoneY
      };
    }, 100);
  }
ngAfterViewInit(){

}
  ngOnInit() {

    /* if( this.model){
       this.crops = [this.cd.add.modelCrop(this.model?.src)];

     }*/

    // this.uploader = new FileUploader({});
    /* this.cd.img.urlToBlob(this.model.src, (el:any)=>{
       this.imageChangedEvent = el;
     })*/
  }

  addToCrops() {
   /* let modelCrop = this.cd.add.modelCrop(this.croppedImage);
    this.cd.array.add(modelCrop, this.crops);
    this.onAdd.emit(modelCrop);
    this.showCropsItems =true;*/
  }
  download() {
  /*  let modelCrop = this.cd.add.modelCrop(this.croppedImage);
    this.cd.saveToDevice(modelCrop,'srcPreview')*/

  }


  setUploadArray($event: any) {
    /*  let clearArray:any =  this.uploader.queue.map((el:any) => {

        let obj:any = {
          file:el.file.rawFile,
        }
        let reader  = new FileReader();
        reader.onloadend = () => {
          // @ts-ignore
          this.oPhoto.emit(reader.result)
          this.uploader.queue = [];
        }
        reader.readAsDataURL(el.file.rawFile);
      return   obj
      });
      this.oPhotoLib.emit(clearArray)*/
    // let first =  this.uploader.queue[0].file.rawFile;
    // let firstreader  = new FileReader();
    // firstreader.onloadend = () => {
    // let file =this.createFile(firstreader.result,first);
    // this.cd.layers.unshift(file)
    // this.cd.selectPhoto =   file
    // this.cd.renderKanva()
    // }
    // @ts-ignore
    // firstreader.readAsDataURL(first);
  }


  generateDataImage(base64: any, model: any) {
    return {
      id: Date.now(),
      type: 'crop',
      ext: model.ext,
      name: model.name,
      src: base64,
      size: {width: 100, height: 100},
      bt: 100,
    };
  }

  // imageChangedEvent: any = '';
  // croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = true;
  transform: ImageTransform = {};

  // fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }
  //
  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  //   console.log(event, base64ToFile(event.base64));
  // }
  //
  // imageLoaded() {
  //   this.showCropper = true;
  //   console.log('Image loaded');
  // }
  //
  // cropperReady(sourceImageDimensions: Dimensions) {
  //   console.log('Cropper ready', sourceImageDimensions);
  // }
  //
  // loadImageFailed() {
  //   console.log('Load failed');
  // }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }


  clearExt(model: any) {
    return '.jpg';
    // return  model.ext.replace('.','')
  }

  protected readonly alert = alert;
}
