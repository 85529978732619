import {Component} from '@angular/core';
import {CdServices} from "../../../services/common/cd.services";
import {
  NgClass,
  NgForOf,
  NgStyle
} from "@angular/common";
import {DrawZoneComponent} from "../../../components/draw/canvas/draw-zone/draw-zone.component";
import {BrushLibPanelCanvasBottomComponent} from "../../../components/draw/brush/brush-lib-panel-canvas-bottom/brush-lib-panel-canvas-bottom.component";
import {BrushPanelCanvasRightComponent} from "../../../components/draw/brush/brush-panel-canvas-right/brush-panel-canvas-right.component";
import {ImagesTabsCanvasTopComponent} from "../../../components/draw/menu/toolbars/images-tabs-canvas-top/images-tabs-canvas-top.component";
import {ToolsPanelCanvasLeftComponent} from "../../../components/draw/menu/toolbars/tools-panel-canvas-left/tools-panel-canvas-left.component";
import {BrushesLibComponent} from "../../../components/draw/brush/brushes-lib/brushes-lib.component";
import {BrushSizeComponent} from "../../../components/draw/brush/brush-size/brush-size.component";
import {BrushColorComponent} from "../../../components/draw/brush/brush-color/brush-color.component";
import {BrushEraseComponent} from "../../../components/draw/brush/brush-erase/brush-erase.component";
import {BrushUndoComponent} from "../../../components/draw/brush/brush-undo/brush-undo.component";
import {PainterComponent} from "../../../components/draw/painter/painter.component";
import {OverlayCanvasComponent} from "../../../components/draw/draw-tools/overlay-canvas/overlay-canvas.component";
@Component({
  selector: 'app-page-sketcher',
  standalone:true,
  providers:[],
  imports: [
    NgForOf,
    NgStyle,
    NgClass,
    DrawZoneComponent,
    BrushLibPanelCanvasBottomComponent,
    BrushPanelCanvasRightComponent,
    ToolsPanelCanvasLeftComponent,
    ImagesTabsCanvasTopComponent,
    BrushesLibComponent,
    BrushSizeComponent,
    BrushColorComponent,
    BrushEraseComponent,
    BrushUndoComponent,
    PainterComponent,
    OverlayCanvasComponent,
  ],
  templateUrl: './page-sketcher.component.html',
})
export class PageSketcherComponent {
  constructor(public cd: CdServices) {}

  public ngOnInit() {}
  public ngAfterViewInit() {}

  public onRenderBeforeStage() {
    // this.konvaTools.setStageSize(480, 640);
    // this.konvaTools.onEnableBrush();
    // this.konvaTools.onScalePlusRenderZone(2);
    // this.konvaTools.goFavoritesTab();
    // this.konvaTools.onShowToolsDraw();
    // this.konvaTools.setBrushFORM('simpleHard');
    // this.konvaTools.setBrushColorHex('#000000');
    // this.konvaTools.onSetBackgroundColor('#e5e5e5');
  }

  public onRenderAfterStage() {
    // this.konvaTools.setBrushSize(1);
    // this.konvaTools.setBrushMode('normal');
  }
  public ngOnDestroy(): void {
    // this.konvaTools.onDestroy()
  }

}

