import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {SidebarComponent} from "../../../common/sidebar/sidebar.component";

import {ToolChromaColorsComponent} from "../../color/tool-chroma-colors/tool-chroma-colors.component";
import {ToolColorHarmonyComponent} from "../../color/tool-color-harmony/tool-color-harmony.component";
import {ToolIroColorsZoomMinusComponent} from "../../color/tool-iro-colors-zoom-minus/tool-iro-colors-zoom-minus.component";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {srcSketch} from "../../../../traits/image-tools";
import {SketchConverterComponent} from "../../../sketch-converter/sketch-converter.component";
import {ZoomImageComponent} from "../../../zoom-image/zoom-image.component";

@Component({
  selector: 'app-overlay-canvas',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    SidebarComponent,
    SketchConverterComponent,
    ZoomImageComponent,
  ],
  templateUrl: './overlay-canvas.component.html',

})
export class OverlayCanvasComponent implements OnInit {
  @Input() imageSrc: any ='assets/overlay-painting.jpg';
  @Input() opacity: any =50;
  @Input() mode: any ='multiply';
  @Input() lockDraw: any ='none';
  public imageSrcSketch: string =srcSketch;
  public blurAmount: number = 10;
  public  selectColor: string;
  public  showSketchConverterComponent: boolean = true;
  constructor(
  ) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  protected readonly imageCrop = srcSketch;
  protected readonly imageSketch = srcSketch;
  public reInitSketchConverter() {
    this.showSketchConverterComponent = false;
    setTimeout(() => {
      this.showSketchConverterComponent = true;
    }, 50)
  }

}
