export const avatarIconImage: string = '/assets/icons/10852416.png';
export const saveIconImage: string = '/assets/icons/save-icons/945102.png';
export const zoomPlusIconImage: string = '/assets/icons/10023736.png';
export const continueIconImage: string = '/assets/icons/colors-tools/2779847.png';
export const listIconImage: string = '/assets/icons/8428362.png';
export const brushesListIconImage: string = '/assets/icons/179600.png';
export const cardIconImage: string = '/assets/icons/867454.png';
export const partyIconImage: string = '/assets/icons/3146600.png';
export const filterIconImage: string = '/assets/icons/7216457.png';
export const brushHardIconImage: string = '/assets/icons/hard-brush.png';
export const brushIconImage: string = '/assets/icons/hard-brush-sketch.png';
export const brushMiddleIconImage: string = '/assets/icons/middle-brush-sketch.png';
export const opacity100IconImage: string = '/assets/icons/round-brush.png';
export const clearIconImage: string = '/assets/icons/clear.png';
export const opacity50IconImage: string = '/assets/icons/opacity-brush.png';
export const brushBigIconImage: string = '/assets/icons/fill-brush.png';
export const eraseSoftIconImage: string = 'assets/icons/erase-soft-brush.png';
export const eraseHardIconImage: string = 'assets/icons/erase-brush.png';
export const photosIconImage: string = '/assets/icons/7763873.png';
export const brushesIconImage: string = '/assets/icons/4472135.png';
export const palletesIconImage: string = '/assets/icons/6919240.png';
export const colorRoundIconImage: string = '/assets/icons/10573532.png';
export const downloadIconImage: string = '/assets/icons/1040236.png';
export const readyIconImage: string = '/assets/icons/6276686.png';
export const bookmarkIconImage: string = '/assets/icons/7222088.png';
export const clockIconImage: string = '/assets/icons/timer.png';
export const ratingIconImage: string = '/assets/icons/2681252.png';
export const newsIconImage: string = '/assets/icons/331953.png';
export const freeIconImage: string = '/assets/icons/6021560.png';
export const premiumIconImage: string = '/assets/icons/premium.png';
export const noSrcPreviewIconImage: string = '/assets/icons/11808042.png';
export const noSrcIconIconImage: string = '/assets/icons/3875172.png';
export const loadingIconImage: string = '/assets/icons/10509663.png';
export const noSrcGifIconImage: string = '/assets/icons/gifs/empty.gif';
export const referencesIconImage: string = '/assets/icons/7102051.png';
export const videosIconImage: string = '/assets/icons/5053028.png';
export const videoIconImage: string = '/assets/icons/5930256.png';
export const levelUpIconImage: string = '/assets/icons/6650064.png';
export const humanIconImage: string = '/assets/icons/6650064.png';
export const animalIconImage: string = '/assets/icons/6650064.png';
export const vegetableIconImage: string = '/assets/icons/6650064.png';
export const fishIconImage: string = '/assets/icons/6650064.png';
