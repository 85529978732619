import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgStyle
} from "@angular/common";
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {OverlayCanvasComponent} from "../../draw-tools/overlay-canvas/overlay-canvas.component";
import {
  windowHeight,
  windowWidth
} from "../../../../traits/window-tools";

@Component({
  selector: 'app-draw-zone',
  standalone: true,
  providers: [],
  imports: [
    NgForOf,
    NgStyle,
    NgClass,
    OverlayCanvasComponent,
  ],
  templateUrl: './draw-zone.component.html',

})
export class DrawZoneComponent implements OnInit {

  @Input() public width: any = windowWidth();
  @Input() public height: any = windowHeight();

  constructor(public drawCanvas: DrawCanvas) {
  }

  ngOnInit() {
    this.drawCanvas.init();
  }
   getParentSize(element) {
    const parent = element.parentElement;
    if (!parent) {
      console.warn("У элемента нет родителя.");
      return null;
    }

    const { width, height } = parent.getBoundingClientRect();
    return { width, height };
  }

ngAfterViewInit(){
 /* const childDiv = document.getElementById('canvas-brush'); // Замените на ID вашего элемента
  const {width,height} = this.getParentSize(childDiv);
  this.width = width;
  this.height = height;*/

}
  ngOnDestroy(): void {
    this.drawCanvas.onDestroy()
  }
}
