<!--<button *ngIf="selectCard"
        class="class-controll bg-gradient-to-r from-purple-500 to-pink-500 hover:from-pink-500 hover:to-purple-500 transition duration-500 ease-in-out transform hover:scale-105"
        (click)="generateRandomId(selectCard)"
>
  <i [class]="theme.icon.random" pBadge value="{{selectCard.id}}_{{selectCard.createAt}}"></i>
</button>-->

<div class="p-3 m-2">
  <ul class="grid grid-cols-1  gap-2 "
      [sortablejs]="cards"
      [sortablejsOptions]="sortableOptions"
  >
    <li *ngFor="let cardItem of cards" [ngClass]="{'selected-card': selectCard == cardItem}">

      <app-site-service-btn *ngIf="cardItem"
                            [name] =cardItem.name
                            [imageSrc] =cardItem.srcPreview
                            (onSelect)="onSelect.emit(cardItem)"
                            (onSave)="onSave.emit(cardItem)"
                            [card]="cardItem"
                            [cards]="cards"
                            [showDublicate]="true"
      >
      </app-site-service-btn>
    </li>
  </ul>
</div>
<hr>
<!--
        <button
          class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
&lt;!&ndash;          <i *ngIf="cardItem.free === true" [class]="theme.icon.gift" (click)="cardItem.free = false; onSave.emit()"></i>&ndash;&gt;
&lt;!&ndash;          <i *ngIf="cardItem.free === false" [class]="theme.icon.premium" (click)="cardItem.free = true;onSave.emit()"></i>&ndash;&gt;
        </button>-->

<!--        <button
          class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
&lt;!&ndash;          <i *ngIf="cardItem.sw === true" [class]="theme.icon.eyeClose" (click)="cardItem.sw = false;"></i>&ndash;&gt;
&lt;!&ndash;          <i *ngIf="cardItem.sw === false" [class]="theme.icon.eyeOpen" (click)="cardItem.sw = true;"></i>&ndash;&gt;
        </button>-->
