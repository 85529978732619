<app-pinch-reference>

<div [style.border]="'5px solid ' + drawCanvas.getBrushColor() +' '"
     [style.background-color]="drawCanvas.getBrushColor()"
>
  <app-brush-color-history (onColor)="reInitChroma()"/>

  <app-eye-dropper *ngIf="(imageSrc  && showReference )"
                   [widthImage]="300"
                   [heightImage]="300"
                   [src]="imageSrc"
                   (onColor)="
                   drawCanvas.setBrushColorHex($event);reInitChroma();
                   color.triggerColorsInOtherTools($event,
                      true,
                      true,
                      true,
                      false
                      );
                  "
  ></app-eye-dropper>
  <app-rectangle-with-color
    *ngIf="(showChroma)"
    [orientation]="'hor'"
    [widthCanvas]="300"
    [heightCanvas]="135"
    [parentColor]="drawCanvas.getBrushColor()"
    (onColor)="drawCanvas.setBrushColorHex($event);
         color.triggerColorsInOtherTools($event,
                      true,
                      true,
                      true,
                      false)
"
  />
</div>
</app-pinch-reference>
<app-brush-fill-progress [change]="drawCanvas.filledPixels"
                         [maxFilledPixels]="1000"
/>
<div class="flex justify-center items-center h-full">

<div class="bg-zinc-600 bg-opacity-80 rounded-lg shadow-lg p-1 w-full h-full flex flex-col space-y-1 relative">
  <div class="z-10  absolute top-[5px] right-[20px] ">
    <app-gif-generator *ngIf="steps"
                       [width]="75"
                       [height]="75"
      [interval]="6"
      [photos]="stepsMap(steps)">
    </app-gif-generator>
  </div>
  <div class="corner-box z-20  absolute top-[80px]   left-1/2 transform -translate-x-1/2    ">
    <div class="flex justify-between items-center bg-zinc-700 rounded-lg p-2 space-x-4 animate__animated animate__fadeInDown">
      <button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-pink-400 transition ease-in-out duration-200"
              (click)="showSteps = true"
      >
           <span class="material-icons text-white">
             <i [class]="photosIcon"></i>
           </span>
      </button>
      <button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-purple-400 transition ease-in-out duration-200"
              (click)="showInfo = true"
      >
           <span class="material-icons text-white">
             <i [class]="libsIcon"></i>
           </span>
      </button>
      <button class="w-8 h-8 bg-zinc-300rounded-full flex justify-center items-center hover:bg-yellow-400 transition ease-in-out duration-200"
              (click)="showVideo = true"
      >
           <span class="material-icons text-white">
             <i [class]="videoIcon"></i>
           </span>
      </button>
    </div>

  </div>

  <!-- Изображение и заголовок урока -->
  <div class="relative bg-zinc-800 rounded-lg overflow-hidden shadow-md">
    <button  class="absolute z-20 top-1 left-0 m-2 bg-zinc-700 rounded-lg p-2 space-x-4  w-8 h-8   flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
            (click)="goToPageChapters()"
    >
      <span class="material-icons text-white">
             <i [class]="arrowBackwardIcon"></i>
      </span>
    </button>

    <img [src]="imageSrc"
         [alt]="name"
         class="w-full h-[80px] object-cover">
    <div class="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center p-4 space-y-2">
      <p class="text-2xl md:text-3xl gradient-text animate__animated animate__fadeInDown">
        {{name}}
      </p>
      <p class="text-sm md:text-base text-white text-center animate__animated animate__fadeInUp">
        {{desc}}
      </p>


    </div>
  </div>


  <div class="bg-zinc-800 rounded-lg p-6 flex justify-center items-center relative animate__animated animate__pulse animate__infinite">
<!--[height]="windowHeight()-180"   -->
    <div  class="w-full h-80 rounded-lg shadow-md" [style.height.px]="windowHeight()-200" >
<!--      <app-pinch-draw-zone>-->
        <app-overlay-canvas *ngIf="imageSrc" [imageSrc]="imageSrc"/>
        <app-draw-zone />
<!--      </app-pinch-draw-zone>-->
    </div>

    <div class="corner-box bottom-0 left-0 m-4">
      <!-- Очистка экрана -->
      <div class="flex justify-start items-center bg-zinc-700 rounded-lg p-2 space-x-4 animate__animated animate__fadeInDown">
            <app-brush-undo [changeAdd]="drawCanvas.isMouseUpCounter"/>

        <!-- <button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-pink-400 transition ease-in-out duration-200"
                 (click)="showSteps = true"
         >
            <span class="material-icons text-white">
              <i [class]="photosIcon"></i>
            </span>
         </button>-->
      </div>

    </div>
    <div class="corner-box bottom-0 right-0 m-2">
      <div class="flex justify-start items-center bg-zinc-700 rounded-lg p-2 space-x-2 animate__animated animate__fadeInDown">

        <!--<button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-pink-400 transition ease-in-out duration-200"
                (click)="showSteps = true"
        >
           <span class="material-icons text-white">
             <i [class]="photosIcon"></i>
           </span>
        </button>-->
      </div>
    </div>
    <div class="corner-box top-0 left-0 m-2">
      <div class="flex justify-start items-center bg-zinc-700 rounded-lg p-2 space-x-2 animate__animated animate__fadeInDown">
        <app-brush-lasso/>
       <!-- <button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-pink-400 transition ease-in-out duration-200"
                (click)="showSteps = true"
        >
           <span class="material-icons text-white">
             <i [class]="photosIcon"></i>
           </span>
        </button>-->
      </div>
    </div>
    <div class="corner-box top-0 right-0 m-2">
      <!-- Повтор действия -->
      <div class="flex justify-start items-center bg-zinc-700 rounded-lg p-2 space-x-2 animate__animated animate__fadeInDown">
        <app-brush-erase/>

      <!--  <button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-pink-400 transition ease-in-out duration-200"
                (click)="showSteps = true"
        >
           <span class="material-icons text-white">
             <i [class]="photosIcon"></i>
           </span>
        </button>-->
      </div>
    </div>
  </div>

  <!-- Нижняя панель управления уроком -->
  <div class="flex justify-between items-center bg-zinc-700 rounded-lg p-2 space-x-2 animate__animated animate__fadeInUp">
    <button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
    (click)="onPrevLesson.emit()"
    >
      <span class="material-icons text-white">
             <i [class]="undoIcon"></i>

      </span>
    </button>
    <button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-red-400 transition ease-in-out duration-200"
            (click)="startLesson()"

    >
      <span class="text-white">
             <i [class]="enterIcon"></i>
      </span>
    </button>
    <button class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
            (click)="onNextLesson.emit()"

    >
      <span class="text-white">
             <i [class]="redoIcon"></i>
      </span>
    </button>
  </div>
</div>
</div>
