import {Injectable} from '@angular/core';
import {
  windowHeight,
  windowWidth
} from "../../traits/window-tools";
import {reInit} from "../../traits/datetime-tools";
@Injectable({
  providedIn: 'root',
})
export class InterfaceTools {
  public lockMove: any = false;
  public showHandMove: any = true;
  public showHandMoveReInit: any = true;
  public eyeDropperSize: any = 125;
  public showBrushesScroll: any = 125;
  public eyeDropperSizeMin: any = 125;
  public eyeDropperSizeMax: any = 250;
  public showEyeDropper: any =true;
  public showOrgonizer: any =true;
  public showSketch: any =true;
  public showBrushSizesList: any =false;
  public saveStorageEnable: any =true;
  public showErase: any =true;
  public enableCloneBrush: any =false;
  public showColorsSketch: any =true;
  public showColorsChroma: any =true;
  public enabledTransform: any =false;
  public showTimerSlider: any =false;
  public showTimerAnimation: any =false;
  public showUploaded: any =true;
  public showSavedStages: any =false;
  public enabledDubleClickTransform: any =false;
  public enabledRender: any =true;
  public showBrushPreview: any =true;
  public showVideos: any =false;
  public showHelp: any =false;
  public showBrush: any =false;
  public showToolsDraw: any =false;
  public showBrushesCard: any =true;
  public showBrushSetting: any =false;
  public showRefCard: any =true;
  public showRotatorSlider: any =false;
  public enabledBrush: any =false;
  public enabledPresure: any =true;
  public changeDetect: any =false;
  public showFoldersCard: any =false;
  public showLayersCard: any =false;
  public showSaveCard: any =false;
  public showCamera: any =true;
  public showLibs: any =true;
  public showSaved: any =false;
  public showDownload: any =true;
  public showErases: any =true;
  public showHistory: any =false;
  public selectCloneLayerModel: any =false;
  public showPalletes: any =false;
  public showBrushListMenu: any =false;
  public activeGalleryIndex: any = 0;
  public tabIdFavorites: any = 0;
  public tabIdCollections: any = 1;
  public tabIdUploads: any = 2;
  public tabIdStages: any = 3;
  public tabIdCrops: any = 4;
  public tabIdLibs: any = 5;
  public activeBrushIndex: any = 0;
  public showSketcherFloatBtn: any = false;
  public showSaveStageBtn: any = true;
  public showEraseBtn: any = true;
  public showClearBtn: any = true;
  public showBrushLibBottomBar: any = true;
  public showRenderZone: any = true;
  public showUploadBtn: any = true;
  public showImagesTopBar: any = true;
  public showHardSoftSwitchBtn: any = true;
  public showEraseBrushSwitchBtn: any = true;
  public showPinsBar: any = true;
  public showHistoryBar: any = true;
  public showColorToolsBar: any = true;
  public showBrushSizesBar: any = true;
  public showBrushPreviewBar: any = false;
  public showInkModeBar: any = true;
  public showBrushesSetting: any = true;
  public showModeAll: any = false;
  public showStageSizes: any = false;
  public showStagesList: any = false;
  public showSketcherFloat: any = false;

  public stageSizes: any = [
    {name: 'screen', height: windowHeight(), width: windowWidth(),},
    {name: '800', height: 800, width: 600,},
    {name: '600', height: 600, width: 800,},
    {name: '500', height: 500, width: 500,},
    {name: '800', height: 800, width: 800,},
  ];

  public onShowEraseBtn() {
    this.showEraseBtn = true;
  }

  public onHideEraseBtn() {
    this.showEraseBtn = false;
  }

  public onShowClearBtn() {
    this.showEraseBtn = true;
  }

  public onHideClearBtn() {
    this.showEraseBtn = false;
  }

  public onShowUploadBtn() {
    this.showUploadBtn = true;
  }

  public onHideUploadBtn() {
    this.showUploadBtn = false;
  }

  public onShowImagesTopBar() {
    this.showImagesTopBar = true;
  }

  public onHideImagesTopBar() {
    this.showImagesTopBar = false;
  }

  public onShowHistoryBar() {
    this.showHistoryBar = true;
  }

  public onHideHistoryBar() {
    this.showHistoryBar = false;
  }

  public onShowBrushSizesBar() {
    this.showBrushSizesBar = true;
  }

  public onHideBrushSizesBar() {
    this.showBrushSizesBar = false;
  }

  public onShowPinsBar() {
    this.showPinsBar = true;
  }

  public onHidePinsBar() {
    this.showPinsBar = false;
  }

  public onShowColorToolsBar() {
    this.showColorToolsBar = true;
  }

  public onHideColorToolsBar() {
    this.showColorToolsBar = false;
  }

  public onShowBrushPreviewBar() {
    this.showBrushPreviewBar = true;
  }

  public onHideBrushPreviewBar() {
    this.showBrushPreviewBar = false;
  }

  public onShowInkModeBar() {
    this.showInkModeBar = true;
  }

  public onHideInkModeBar() {
    this.showInkModeBar = false;
  }

  public onShowHardSoftSwitchBtn() {
    this.showHardSoftSwitchBtn = true;
  }

  public onHideHardSoftSwitchBtn() {
    this.showHardSoftSwitchBtn = false;
  }

  public onShowEraseBrushSwitchBtn() {
    this.showEraseBrushSwitchBtn = true;
  }

  public onHideEraseBrushSwitchBtn() {
    this.showEraseBrushSwitchBtn = false;
  }

  public onShowSaveStageBtn() {
    this.showSaveStageBtn = true;
  }

  public onHideSaveStageBtn() {
    this.showSaveStageBtn = false;
  }

  public onShowBrushLibBottomBar() {
    this.showBrushLibBottomBar = true;
  }

  public onHideBrushLibBottomBar() {
    this.showBrushLibBottomBar = false;
  }

  public onShowRenderZone() {
    this.showRenderZone = true;
  }

  public onHideRenderZone() {
    this.showRenderZone = false;
  }

  public onShowSketcherFloatBtn() {
    this.showSketcherFloatBtn = true;
  }

  public onHideSketcherFloatBtn() {
    this.showSketcherFloatBtn = false;
  }

  public goFavoritesTab() {
    this.activeGalleryIndex = this.tabIdFavorites;
  }

  public goCollectionsTab() {
    this.activeGalleryIndex = this.tabIdCollections;
  }

  public goUploadsTab() {
    this.activeGalleryIndex = this.tabIdUploads;
  }

  public goStagesTab() {
    this.activeGalleryIndex = this.tabIdStages;
  }

  public goCropTab() {
    this.activeGalleryIndex = this.tabIdCrops;
  }

  public goLibsTab() {
    this.activeGalleryIndex = this.tabIdLibs;
  }
  public onShowToolsDraw() {
    this.showToolsDraw = true;
  }


  public onEnableBrushToggle() {
    this.showSketcherFloat  = !this.showSketcherFloat
  }




  public onHideToolsDraw() {
    this.showToolsDraw = false;
  }
  public onShowStagesList() {
    this.showStagesList = true;
  }
  public onToggleStagesList() {
    this.showStagesList = !this.showStagesList;
  }

  public onHideStagesList() {
    this.showStagesList = false;
  }

  public onHideEyeDropper() {
    // this.showBrushesTools = true;
    this.showEyeDropper = false;
    // this.selectBrush = this.previewBrush;
    // this.buildStage();

  }
  public onReInitBrushPreview() {
    this.showBrushPreview = true;
    reInit(() => {
      this.showBrushPreview = false;
    }, 500);
  }
  public onBrushesScrollToggle() {
    this.showBrushesScroll = !this.showBrushesScroll;
  }
  public onShowEyeDropper() {
    // this.setStageToSrc();
    this.showEyeDropper = true;
    /* this.selectBrush = {
         name: 'eyeDropper',
         value: 'eyeDropper',
         icon: 'fa-solid fa-paintbrush',
         srcPreview: 'assets/params/images/fill-brush.png',
     };*/
    // this.showBrushesTools = false;
  }

  public onReInitInteract() {
    this.showHandMoveReInit = false;
    reInit(() => {
      this.showHandMoveReInit = true;
    }, 500);
  }
  public onHandMoveStart() {
    this.showHandMove = true;
    this.lockMove = true;
    // this.onDisableBrush();
  }


  public onHandMoveStop() {
    this.showHandMove = false;
    this.lockMove = false;
    // this.onEnableBrush();
    // this.onHideEyeDropper();
  }
  public onDropperSize(size: any = 300) {
    this.eyeDropperSize = size;
  }

}
