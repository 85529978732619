

<app-duo-lesson
                [name]="lesson.name"
                [desc]="lesson.desc"
                [imageSrc]="lesson.srcPreview"
                [steps]="lesson.favorites"
/>
<!--<app-sidebar [show]="true"
             [showClose]="true"
             [height]="100"
>


  <div
    class=" flex flex-col justify-center items-start row-start-1 sm:row-start-1">
    <div  class="w-full h-full">

      <app-video-reader-course
        [videoItem]="lesson"
        [timeList]="lesson.steps"
        [selectVideo]="lesson"
        [autoplay]="true"
        [controls]="false"
        [options]="{
                      controls: false,
                      sources: {
                        src: lesson.srcVideo,
                        type: 'video/mp4'
                        }
                     }"
      >
      </app-video-reader-course>
    </div>

  </div>

</app-sidebar>-->
