<div *ngIf="this.photos && srcGif"
     class="   py-1 px-3 rounded-br-lg rounded-full">
 <!-- <img  *ngIf="!zoomClick" (click)=" zoomClick  = true"
       [src]="theme.iconSrc.zoomPlus"
       alt="premium-icon"
       class="z-20 absolute top-[10px] right-[20px] [20px] w-[20px] opacity-75 "
  >-->
  <img *ngIf="zoomClick"
       [src]="srcGif"
       (click)=" zoomClick  = false"
       [style.width.px]="width"
       [style.height.px]="height"
       class="aspect-square focus:ring-4 shadow-lg transform scale-[1.8]  transition-transform rounded-lg "
       alt="preview_video"
  >
  <img *ngIf="!zoomClick"
       (click)=" zoomClick  = true"
       [src]="srcGif"
       [style.width.px]="width"
       [style.height.px]="height"
       class="aspect-square focus:ring-4 shadow-lg transform scale-100 transition-transform rounded-lg  "
       alt="preview_video"
  >
</div>
<div *ngIf="!srcGif"
     class=" animate-pulse animate-infinite animate-ease-in-out animate-duration-[1000ms] absolute -right-[2px] bottom-[5px]  py-1 px-3 rounded-br-lg rounded-full">
  <!--<img
       [src]="theme.iconSrc.loading"
       class="focus:ring-4 shadow-lg transform scale-100 active:scale-150 transition-transform rounded-lg shadow-inner w-[75px] h-[75px]"
       alt="preview_video"
  >-->
</div>
