import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BrushesToolsServices} from "../../../../services/konva/brushes-tools.services";
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {InterfaceTools} from "../../../../services/konva/InterfaceTools";
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {
  brushesListIconImage,
  brushHardIconImage,
  eraseHardIconImage
} from "../../../../traits/iconsImages.services";
import {SidebarComponent} from "../../../common/sidebar/sidebar.component";
import {
  closeIcon,
  colorHistoryIcon,
  colorLibIcon,
  eyeDropperIcon,
  palleteIcon,
  palleteLibIcon
} from "../../../../traits/icons-tools";
import {scrollParams} from "../../../../traits/window-tools";
import {ToolChromaColorsComponent} from "../../color/tool-chroma-colors/tool-chroma-colors.component";
import {ToolColorHarmonyComponent} from "../../color/tool-color-harmony/tool-color-harmony.component";
import {ToolIroColorsZoomMinusComponent} from "../../color/tool-iro-colors-zoom-minus/tool-iro-colors-zoom-minus.component";
import {KnvColor} from "../../../../services/konva/KnvColor";
import {ScrollPanelModule} from "primeng/scrollpanel";

@Component({
  selector: 'app-brush-erase',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    SidebarComponent,
    ToolChromaColorsComponent,
    ToolColorHarmonyComponent,
    ToolIroColorsZoomMinusComponent,
    ScrollPanelModule,
  ],
  templateUrl: './brush-erase.component.html',

})
export class BrushEraseComponent implements OnInit {
  @Input() items: any;
  @Output() onCollection = new EventEmitter();
  constructor(
    public drawCanvas:DrawCanvas,
    public interfaceTools:InterfaceTools,
    public brushesTools:BrushesToolsServices,
    public color:KnvColor,
  ) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }


  protected readonly brushesListIconImage = brushesListIconImage;
  protected readonly colorLibIcon = colorLibIcon;
  protected readonly scrollParams = scrollParams;
  protected readonly eyeDropperIcon = eyeDropperIcon;
  protected readonly closeIcon = closeIcon;
  protected readonly palleteLibIcon = palleteLibIcon;
  protected readonly palleteIcon = palleteIcon;
  protected readonly colorHistoryIcon = colorHistoryIcon;
  protected readonly eraseHardIconImage = eraseHardIconImage;
  protected readonly brushHardIconImage = brushHardIconImage;
}
