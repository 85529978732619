import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import ColorThief from "../../../assets/params/js/color-thief.umd.js";
import {
  getPixel,
  getPosition
} from 'src/app/services/common/draw-methods';
import {addToStart} from "../../traits/array-tools";
import {dateNow} from "../../traits/datetime-tools";
import {
  brightenGradient,
  darkenGradient,
  desaturateGradient,
  opositeGradient,
  saturateGradient,
  tetradicThreeGradient
} from "../../services/common/color-tools";
@Component({
  selector: 'app-circle-with-color',
  templateUrl: './circle-with-color.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe
  ]
})
export class CircleWithColorComponent {
  @ViewChild('canvas', {static: true}) canvas: any;
  @Input() parentColor: string = '#b60101';
  @Input() colors: string[] = ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet', 'purple', 'cyan', 'magenta', 'pink', 'brown'];
  @Input() scaleCanvas: string = '75%';
  @Input() colorsEyeDropper: string[] = [
    'red',
    'orange',
    'yellow'
  ];
  @Input() customColors: string[] = [
    'red',
    'orange',
    'yellow'
  ];
  @Input() imageSrc: string = 'assets/avatars animals/10005358.png';
  @Input() imageSize: number = 225;
  @Input() cellSize: number = 50;
  @Input() smallCircleRadius: number = 10;
  @Input() numSmallCircles: number = 50;
  @Input() numCustomCircles: number = 50;
  @Input() radiusSaturate: number = 200;
  @Input() customCircleRadius: number = 180;
  @Input() textLogo: string = 'art-club.info';
  @Input() textSize: number = 12;
  @Output() onChange = new EventEmitter<string>();
  @Output() onGetPallete = new EventEmitter<string>();
  @Output() onColor = new EventEmitter<string>();
  @Output() imageSaved = new EventEmitter<void>();
  public saturate: string[] = [];
  public brighten:  string[] = [];
  public darken:  string[] = [];
  public opacity:  string[] = [];
  public luminance:  string[] = [];
  public showEyeDropper: boolean = true
 public  ngAfterViewInit() {
    this.init();
  }

  init() {
    this.drawCircle();
  }

  public reset(){
    function resetElement(element) {
      const parent = element.parentNode;
      const newElement = element.cloneNode(true);
      parent.replaceChild(newElement, element);
      return newElement;
    }
    resetElement(this.canvas.nativeElement);
            /*function resetElementById(id) {
          const oldElement = document.getElementById(id);
          console.log(oldElement)
          if (oldElement) {
            const newElement = oldElement.cloneNode(true);
            oldElement.parentNode.replaceChild(newElement, oldElement);
            return newElement;
          } else {
            console.error('Element with id ' + id + ' not found.');
            return null;
          }*/
    const oldElement = document.getElementById('canvasWrapper');
    console.log(oldElement)
        }
  drawCircle() {
    let canvas: any = document.getElementById("canvas");// this.canvas.nativeElement;
    const ctx: any = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const numLargeCircles = this.colors.length;
    // const largeAngleIncrement = (2 * Math.PI) / numLargeCircles;
    // const smallAngleIncrement = (2 * Math.PI) / this.numSmallCircles;
    this.drawLogo(ctx, this.textSize, canvas.height - this.textSize);
    this.drawColorCenter(ctx, centerX, centerY);
    this.drawRoundArray(ctx, centerX, centerY, saturateGradient(this.parentColor,40),140,40,7);
    this.drawRoundArray(ctx, centerX, centerY, darkenGradient(this.parentColor,30),120,30,8);
    this.drawRoundArray(ctx, centerX, centerY, desaturateGradient(this.parentColor,30),100 ,30,7);
    this.drawRoundArray(ctx, centerX, centerY, brightenGradient(this.parentColor,20),80,20,8);
    this.drawRoundArray(ctx, centerX, centerY, tetradicThreeGradient(this.parentColor,15),60,15,8);
    this.drawRoundArray(ctx, centerX, centerY, opositeGradient(this.parentColor,15),40,15,7);
    const image: string = canvas.toDataURL('image/png');
    this.onGetPallete.emit(image);

   const getColorHendler = (event)=>{
     const position =  getPosition(event);
      const colorHex  =getPixel(canvas,position);
          this.onColor.emit(colorHex)
     console.log(colorHex)
    }
    canvas.addEventListener("click", getColorHendler);
    canvas.addEventListener("touchstart", getColorHendler);
  }

  drawLogo(ctx: CanvasRenderingContext2D, centerX: number, centerY: number) {
    ctx.font = `${this.textSize}px Arial`;
    ctx.fillStyle = 'white'//this.colors[0];
    // ctx.globalCompositeOperation = 'source-over'
    ctx.fillText(this.textLogo, centerX, centerY)


  }
  drawColorCenter(ctx: CanvasRenderingContext2D, centerX: number, centerY: number,callback:any = null) {
    var radius = 25;
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.fillStyle = this.parentColor;
    ctx.fill();
      callback &&callback()
  }
  drawRoundArray(ctx: CanvasRenderingContext2D,
               centerX: number,
               centerY: number,
               array:string[],
               radiusSaturate: number = 200,
               numSmallCircles:number =15,
               radius:number =10

  ) {
    // let numSmallCircles =array.length;
    for (let i = 0; i < numSmallCircles; i++) {
      const angle = i *   (2 * Math.PI) / numSmallCircles;
      const x = centerX + radiusSaturate * Math.cos(angle);
      const y = centerY + radiusSaturate * Math.sin(angle);
      const colorCircleHex  =array[i % numSmallCircles];
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.fillStyle = colorCircleHex
      ctx.fill();
    }
  }


  public getImage() {
    const canvas = this.canvas.nativeElement;
    const image: string = canvas.toDataURL('image/png');
    return image;
  }

  downloadImage() {
    const a = document.createElement('a');
    a.href = this.getImage();
    a.download = `art-club.info (pallete-${dateNow()}).png`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.imageSaved.emit();
  }
  public desaturate:  string[] = [];
  public oposite:  string[] = [];
  public complementaryOne:  string[] = [];
  public splitOne:  string[] = [];
  public splitTwo:  string[] = [];
  public analogousOne:  string[] = [];
  public analogousTwo:  string[] = [];
  public analogousThree:  string[] = [];
  public triadicOne: string[] = [];
  public triadicTwo: string[] = [];
  public tetradicOne: string[] = [];
  public tetradicTwo: string[] = [];
  public tetradicThree: string[] = [];

  getImageColors(src: string, callback: any = null) {
    let colorThief = new ColorThief();
    let res = {
      main: {hex: 'white'},
      all: [],
      colorsGradient: [],
    };
    // const img = new Image();
    let img = new Image();// document.createElement('img');
    img.crossOrigin = 'Anonymous';
    img.src = src;
    img.onload = () => {
      const toColor = (color: any) => {
        // @ts-ignore
        let wcolor = w3color(color);
        return [
          // {name:'toName',value: wcolor.toName()},
          {
            name: 'toHexString',
            value: wcolor.toHexString()
          },
          // {name: 'toCmykString', value: wcolor.toCmykString()},
          // {name: 'toNcolString', value: wcolor.toNcolString()},
          // {name: 'toRgbString', value: wcolor.toRgbString()},
          // {name: 'toHslString', value: wcolor.toHslString()},
          // {name: 'toHwbString', value: wcolor.toHwbString()},
        ];
      };
      res.all = colorThief.getPalette(img).map((rgb: any) => {
        const rgbToHex = (r: any, g: any, b: any) => '#' + [
          r,
          g,
          b
        ].map(x => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        }).join('');
        let colorHex = rgbToHex(rgb[0], rgb[1], rgb[2]);
        return colorHex;// {hex: colorHex};

      });
      res.main = res.all[0];
      callback && callback(res.all);
      // objectParams.colorsGradient = colorsGradient(objectParams.colors);

    };
    return res;
  };

}
