import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {CdServices} from "../../../services/common/cd.services";
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import { ToolsPanelCanvasLeftComponent } from '../menu/toolbars/tools-panel-canvas-left/tools-panel-canvas-left.component';
import {BrushPanelCanvasRightComponent } from '../brush/brush-panel-canvas-right/brush-panel-canvas-right.component';
import {ImagesTabsCanvasTopComponent } from '../menu/toolbars/images-tabs-canvas-top/images-tabs-canvas-top.component';
import {BrushColorComponent } from '../brush/brush-color/brush-color.component';
import { BrushUndoComponent } from '../brush/brush-undo/brush-undo.component';
import {BrushEraseComponent} from "../brush/brush-erase/brush-erase.component";
import {BrushSizeComponent} from "../brush/brush-size/brush-size.component";
import {BrushesLibComponent} from "../brush/brushes-lib/brushes-lib.component";
import {DrawZoneComponent} from "../canvas/draw-zone/draw-zone.component";
import {DrawDownloadComponent} from "../brush/draw-download/draw-download.component";
import {DrawClearComponent} from "../brush/draw-clear/draw-clear.component";
import {BackgroundColorComponent} from "../brush/background-color/background-color.component";
import {BrushColorHistoryComponent} from "../color/brush-color-history/brush-color-history.component";
import {BrushCursorComponent} from "../brush/brush-cursor/brush-cursor.component";
import {DrawCanvas} from "../../../services/konva/DrawCanvas";
import {BrushLassoComponent} from "../brush/brush-lasso/brush-lasso.component";
import {BrushSmudgeComponent} from "../brush/brush-smudge/brush-smudge.component";
import {OverlayCanvasComponent} from "../draw-tools/overlay-canvas/overlay-canvas.component";
import {DrawCompareComponent} from "../brush/draw-compare/draw-compare.component";
import {PinchReferenceComponent} from "../draw-tools/pinch-reference/pinch-reference.component";
import {EyeDropperComponent} from "../../eye-dropper/eye-dropper.component";
import {PinchDrawZoneComponent} from "../draw-tools/pinch-draw-zone/pinch-draw-zone.component";
import {RecordVideoCanvasComponent} from "../canvas/record-video-canvas/record-video-canvas.component";
import {BrushFillProgressComponent} from "../brush/brush-fill-progress/brush-fill-progress.component";
import {PalleteGeneratorColorComponent} from "../../pallete-generator-color/pallete-generator-color.component";
import {CircleWithColorComponent} from "../../circle-with-color/circle-with-color.component";
import {RectangleWithColorComponent} from "../../rectangle-with-color/rectangle-with-color.component";
import {ZoomImageComponent} from "../../zoom-image/zoom-image.component";
import {KnvColor} from "../../../services/konva/KnvColor";
import {UploadImagesComponent} from "../upload-images/upload-images.component";
import {base64ImageToSquare} from "../../../traits/image-tools";
@Component({
  selector: 'app-painter',
  standalone:true,
  providers:[],
  imports: [
    NgForOf,
    NgStyle,
    NgClass,
    BrushPanelCanvasRightComponent,
    ToolsPanelCanvasLeftComponent,
    ImagesTabsCanvasTopComponent,
    BrushesLibComponent,
    BrushSizeComponent,
    BrushColorComponent,
    BrushEraseComponent,
    BrushUndoComponent,
    DrawZoneComponent,
    DrawDownloadComponent,
    DrawClearComponent,
    BackgroundColorComponent,
    BrushColorHistoryComponent,
    BrushCursorComponent,
    BrushLassoComponent,
    BrushSmudgeComponent,
    OverlayCanvasComponent,
    DrawCompareComponent,
    PinchReferenceComponent,
    EyeDropperComponent,
    PinchDrawZoneComponent,
    RecordVideoCanvasComponent,
    BrushFillProgressComponent,
    PalleteGeneratorColorComponent,
    CircleWithColorComponent,
    NgIf,
    RectangleWithColorComponent,
    ZoomImageComponent,
    UploadImagesComponent,
  ],
  templateUrl: './painter.component.html',
})
export class PainterComponent {
  @Input() brushColor: any = this.drawCanvas.getBrushColor();

 public showChroma: any = true;
 public showReference: any = true;
  public referenceSrc: any = 'assets/cinema.png';
  @Output() onColor = new EventEmitter();
  protected reInitChroma() {
    this.showChroma = false;
    setTimeout(() => {
      this.showChroma = true;
    }, 50)
  }
  protected reInitReference() {
    this.showReference = false;
    setTimeout(() => {
      this.showReference = true;
    }, 50)
  }

  constructor(public drawCanvas:DrawCanvas, public color:KnvColor,) {}

  public ngOnInit() {
  }
  public ngAfterViewInit() {

  }

  public ngOnDestroy(): void {
  }

  setReferenceHandler($event: string[]) {
    base64ImageToSquare($event[0])
      .then((squareBase64Image) => {
        console.log(squareBase64Image)
        this.referenceSrc= squareBase64Image;

      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });
  }




}

