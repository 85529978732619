import {
  changeImageStrokeItem,
  getContext
} from "./canvas-tools";

export function angleBetween(point1: any, point2: any) {
  return Math.atan2(point2.x - point1.x, point2.y - point1.y);
}

export function distanceBetween(point1: any, point2: any) {
  return Math.sqrt((point2.x - point1.x) * (point2.x - point1.x) + (point2.y - point1.y) * (point2.y - point1.y));
}

export function countFilledPixels(canvas: any, x: number, y: number, filledPixels: number = 0, max: number = 1000, callback: any) {
  let ctx = getContext(canvas)
  const imageData = ctx.getImageData(x, y, 1, 1);
  const pixel = imageData.data;

  if (pixel[0] !== 255 || pixel[1] !== 255 || pixel[2] !== 255) {
    filledPixels++;
    if (filledPixels > max) {
      filledPixels = max;
    }
    callback && callback(filledPixels)
  }
}

export function getPosition(event: any) {
  const clientX = event.touches ? event.touches[0].pageX : event.pageX;
  const clientY = event.touches ? event.touches[0].pageY : event.pageY;

  const rect = event.target.getBoundingClientRect();
  const scaleX = event.target.width / rect.width;
  const scaleY = event.target.height / rect.height;

  const canvasX = (clientX - rect.left - document.documentElement.scrollLeft) * scaleX;
  const canvasY = (clientY - rect.top - document.documentElement.scrollTop) * scaleY;

  return {
    x: canvasX,
    y: canvasY
  };
  /* const clientX = event.touches ? event.touches[0].pageX : event.pageX;
   const clientY = event.touches ? event.touches[0].pageY : event.pageY;

   const rect = event.target.getBoundingClientRect();
   const scaleX = event.target.width / rect.width;
   const scaleY = event.target.height / rect.height;

   const canvasX = (clientX - rect.left - window.scrollX) * scaleX;
   const canvasY = (clientY - rect.top - window.scrollY) * scaleY;

   return { x: canvasX, y: canvasY };*/
  /*  const rect = event.target.getBoundingClientRect();
    const scaleX = event.target.width / rect.width;
    const scaleY = event.target.height / rect.height;
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    const canvasX = (clientX - rect.left) * scaleX;
    const canvasY = (clientY - rect.top) * scaleY;

    return { x: canvasX, y: canvasY };*/


  /*  const clientX = event.clientX || event['touches'][0].clientX;
    const clientY = event.clientY || event['touches'][0].clientY;
    const {offsetLeft, offsetTop} = event.target;
    const canvasX = clientX - offsetLeft;
    const canvasY = clientY - offsetTop;

    return {x: canvasX, y: canvasY};*/
}

export function rgb2hex(pixelData: any) {
  return '#' + ('0' + pixelData[0].toString(16)).slice(-2) + ('0' + pixelData[1].toString(16)).slice(-2) + ('0' + pixelData[2].toString(16)).slice(-2);
}

export function isCanvasBlank(canvas: any) {
  const context = canvas.getContext('2d');

  const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);

  return !pixelBuffer.some(color => color !== 0);
}

export function getPixel(canvas: any, position: any = {
  x: 0,
  y: 0
}) {
  const context = getContext(canvas);
  let pixelData: any = context.getImageData(position.x, position.y, 1, 1).data;
  let hex: any = rgb2hex(pixelData);
  if (hex !== '#000000' ||hex === undefined ) {
    return hex;
  }
}

export function brushImage(context: any, imgObject: any, position: any = {
  x: 0,
  y: 0
}) {
  context.drawImage(imgObject, position.x - (imgObject.width / 2), position.y - (imgObject.height / 2), imgObject.width, imgObject.height);
}

export function brushArc(context: any, position: any = {
  x: 0,
  y: 0
}, params: any = {size: 10}) {
  context.beginPath();
  context.arc(position.x, position.y, params.size, false, Math.PI * 2, false);
  context.closePath();
  context.fill();
  context.stroke();
}


export function srcToStroke(src: any, params: any = {
  colorHex: '#FFFFFF',
  height: 20,
  width: 20,
  blurFilter: 0,
  opacityFilter: 10,
  globalCompositeOperation: 'normal',
  globalAlpha: 1,
}, callback: any) {
  changeImageStrokeItem(src, params, (base64Stroke: any) => {
    let imgObjectResult: any = document.createElement('img');
    imgObjectResult.crossOrigin = 'Anonymous';
    imgObjectResult.width = params.width;
    imgObjectResult.height = params.height;
    imgObjectResult.onload = () => {
    };
    imgObjectResult.src = base64Stroke;
    callback && callback(imgObjectResult);
  });

}
