import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {JsonPipe, NgFor, NgIf} from '@angular/common';
import {EditChildrenComponent} from '../edit-children/edit-children.component';
import {Card} from '../../../interfaces/Card';
import {EditListCardsComponent} from '../edit-list-cards/edit-list-cards.component';
import {SiteDebugerComponent} from "../site-debuger/site-debuger.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {windowWidth} from "../../../traits/window-tools";
import {EditParentComponent} from "../edit-parent/edit-parent.component";

@Component({
  selector: 'app-site-service-select',
  templateUrl: './site-service-select.component.html',
standalone:true,
  imports: [
    NgFor,
    NgIf,
    JsonPipe,
    EditListCardsComponent,
    EditChildrenComponent,
    SiteDebugerComponent,
    SidebarComponent,
    EditParentComponent
  ]

})
export class SiteServiceSelectComponent {
  @ViewChild('select') select: ElementRef<HTMLCanvasElement>;

  card:any;
  @Input() cardsMessenges: any[];
  @Input() cardsAdditions: any[];
  @Input() data: any;
  @Output() onSelect:EventEmitter<any> = new EventEmitter();
   public showSelectCard: boolean =false;
constructor() {
  console.log(this.data,'data')
}

  onSelectCard(card: any) {
  this.card = card;
  this.cardsMessenges = this.data.site.organizer.messenges;
  // this.cardsAdditions = this.data.site.additions.messenges;
    this.showSelectCard = true;
    // this.select.card = $event;

    // @ts-ignore
    // this.select.card = $event;
    // @ts-ignore
    // this.select.cards = data?.site.organizer.messenges;
    // this.showSelectCard = true;
  }

  protected readonly windowWidth = windowWidth;
}
