import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  effect,
  SimpleChanges
} from '@angular/core';
import {BrushesToolsServices} from "../../../../services/konva/brushes-tools.services";
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {InterfaceTools} from "../../../../services/konva/InterfaceTools";
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {brushesListIconImage} from "../../../../traits/iconsImages.services";
import {SidebarComponent} from "../../../common/sidebar/sidebar.component";
import {
  brushIcon,
  undoIcon
} from "../../../../traits/icons-tools";
import {BadgeModule} from "primeng/badge";
import {cloneModel} from "../../../../traits/model-tools";
import {
  getImageData,
  imageDataToBase64
} from "../../../../services/common/canvas-tools";
import {randomId} from "../../../../traits/string-tools";
import {addToEnd} from "../../../../traits/array-tools";

@Component({
  selector: 'app-brush-undo',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    SidebarComponent,
    BadgeModule,
  ],
  templateUrl: './brush-undo.component.html',

})
export class BrushUndoComponent implements OnInit {
  public historySelect: any;
  public historyBrushList: any = [];
  public historyIndex: any = -1;
  @Input() project: any;
  @Input() limitHistory: any = 1000;
  @Input() items: any;
  @Input() changeAdd: any;
  @Input() changeClear: any;
  @Output() onRender = new EventEmitter();
  constructor(
    public drawCanvas:DrawCanvas,
    public interfaceTools:InterfaceTools,
    public brushesTools:BrushesToolsServices,
  ) {

  }

  ngOnInit() {
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes['changeAdd'] && changes['changeAdd']?.previousValue != changes['changeAdd']?.currentValue) {
      this.addToStrokeToHistory();
    }
  }

  public addToStrokeToHistory() {
    if(this.drawCanvas.canvasBrush){
      const imageData = getImageData(this.drawCanvas.canvasBrush);
      const srcStroke = imageDataToBase64(imageData)
      const historyItem = {
        id: randomId(),
        createAt: Date.now(),
        srcStroke: srcStroke,
      };
      if (this.historyBrushList.length < 1000) {
        addToEnd(historyItem, this.historyBrushList);
        this.historyIndex += 1;
      }
    }

  }

  ngAfterViewInit() {
  }
  public undoHistory() {

    /*if (this.drawCanvas.historyIndex <= 0) {
      console.log("No more history to undo.");
      return;
    }

    // Получаем текущее состояние для восстановления
    const historyState = this.drawCanvas.historyBrushList[this.drawCanvas.historyIndex - 1];

    if (historyState) {
      // Восстанавливаем состояние
      this.drawCanvas.restoreState(historyState.srcStroke);

      // Уменьшаем индекс после восстановления
      this.drawCanvas.historyIndex -= 1;
    } else {
      console.error("History state not found.");
    }*/
  }



  protected readonly brushesListIconImage = brushesListIconImage;
  protected readonly brushIcon = brushIcon;
  protected readonly undoIcon = undoIcon;

}
