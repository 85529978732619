<!--
[sortablejs]="colors"
[sortablejsOptions]="cd.sortableOptions('colors')"
-->
<!--[hidden]="showHistoryColors"-->
<div
     [style.width.px]="widthList"
     [style.height.px]="heightList"

     class="scroll-items"
>
  <ng-container *ngFor="let  colorItem  of colors;let first = first;let number  = index">
    <div class="item-scroll">
    <app-color-item
      [colorHex]="colorItem.hex"
      (onClick)="onColor.emit(colorItem.hex)"
      (onDblclick)="onDblclick.emit(colorItem.hex)"
      (onPress)="onPress.emit(colorItem.hex)"
    ></app-color-item>
    </div>
  </ng-container>
 <!-- <app-tool-list-layers
    *ngIf="colors"
    [view]="view"
    [cols]="6"
    [gap]="2"
    [heightList]="heightList"
    [widthList]="widthList"
    [models]="colors"
    [widthItem]="widthItem"
    [heightItem]="heightItem"
    [showDelete]="true"
    [showSort]="false"
    [showClear]="true"
    [showShow]="false"
    [showColor]="true"
    [showLock]="false"
    (onColor)="onColor.emit($event)"
  ></app-tool-list-layers>-->
<!--</div>-->
</div>
