import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {DataSites} from '../../../services/pages/data-sites';
import {PaginatorModule} from "primeng/paginator";
import {SiteServiceBtnComponent} from "../site-service-btn/site-service-btn.component";

@Component({
  selector: 'app-site-services-list',
  templateUrl: './site-services-list.component.html',
standalone:true,
  imports: [
    NgFor,
    NgIf,
    PaginatorModule,
    SiteServiceBtnComponent
  ]

})
export class SiteServicesListComponent {

  @Input() serviceSites: Array<any>;
  @Output() onSelect:EventEmitter<any> = new EventEmitter();

  constructor() {
    this.getSites();
  }
  getSites(){
    this.serviceSites  = inject(DataSites).list();
  }

}
