import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {BrushPanelCanvasRightComponent} from "../brush/brush-panel-canvas-right/brush-panel-canvas-right.component";
import {ToolsPanelCanvasLeftComponent} from "../menu/toolbars/tools-panel-canvas-left/tools-panel-canvas-left.component";
import {ImagesTabsCanvasTopComponent} from "../menu/toolbars/images-tabs-canvas-top/images-tabs-canvas-top.component";
import {BrushesLibComponent} from "../brush/brushes-lib/brushes-lib.component";
import {BrushSizeComponent} from "../brush/brush-size/brush-size.component";
import {BrushColorComponent} from "../brush/brush-color/brush-color.component";
import {BrushEraseComponent} from "../brush/brush-erase/brush-erase.component";
import {BrushUndoComponent} from "../brush/brush-undo/brush-undo.component";
import {DrawZoneComponent} from "../canvas/draw-zone/draw-zone.component";
import {DrawDownloadComponent} from "../brush/draw-download/draw-download.component";
import {DrawClearComponent} from "../brush/draw-clear/draw-clear.component";
import {BackgroundColorComponent} from "../brush/background-color/background-color.component";
import {BrushColorHistoryComponent} from "../color/brush-color-history/brush-color-history.component";
import {BrushCursorComponent} from "../brush/brush-cursor/brush-cursor.component";
import {BrushLassoComponent} from "../brush/brush-lasso/brush-lasso.component";
import {BrushSmudgeComponent} from "../brush/brush-smudge/brush-smudge.component";
import {OverlayCanvasComponent} from "../draw-tools/overlay-canvas/overlay-canvas.component";
import {DrawCompareComponent} from "../brush/draw-compare/draw-compare.component";
import {PinchReferenceComponent} from "../draw-tools/pinch-reference/pinch-reference.component";
import {EyeDropperComponent} from "../../eye-dropper/eye-dropper.component";
import {PinchDrawZoneComponent} from "../draw-tools/pinch-draw-zone/pinch-draw-zone.component";
import {RecordVideoCanvasComponent} from "../canvas/record-video-canvas/record-video-canvas.component";
import {BrushFillProgressComponent} from "../brush/brush-fill-progress/brush-fill-progress.component";
import {PalleteGeneratorColorComponent} from "../../pallete-generator-color/pallete-generator-color.component";
import {CircleWithColorComponent} from "../../circle-with-color/circle-with-color.component";
import {RectangleWithColorComponent} from "../../rectangle-with-color/rectangle-with-color.component";
import {ZoomImageComponent} from "../../zoom-image/zoom-image.component";
import {uploadesIcon} from "../../../traits/icons-tools";

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  standalone:true,
  providers:[],
  imports: [
    NgForOf,
    NgStyle,
    NgClass,
    BrushPanelCanvasRightComponent,
    ToolsPanelCanvasLeftComponent,
    ImagesTabsCanvasTopComponent,
    BrushesLibComponent,
    BrushSizeComponent,
    BrushColorComponent,
    BrushEraseComponent,
    BrushUndoComponent,
    DrawZoneComponent,
    DrawDownloadComponent,
    DrawClearComponent,
    BackgroundColorComponent,
    BrushColorHistoryComponent,
    BrushCursorComponent,
    BrushLassoComponent,
    BrushSmudgeComponent,
    OverlayCanvasComponent,
    DrawCompareComponent,
    PinchReferenceComponent,
    EyeDropperComponent,
    PinchDrawZoneComponent,
    RecordVideoCanvasComponent,
    BrushFillProgressComponent,
    PalleteGeneratorColorComponent,
    CircleWithColorComponent,
    NgIf,
    RectangleWithColorComponent,
    ZoomImageComponent,
  ],
})
export class UploadImagesComponent {
  @Input() multiple: boolean = false; // Переключатель для загрузки одного или нескольких изображений
  @Output() imagesLoaded = new EventEmitter<string[]>(); // Изменен на массив строк для нескольких изображений

  onFileSelected(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const base64Array: string[] = [];

      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
          base64Array.push(reader.result as string);
          if (base64Array.length === files.length) {
            this.imagesLoaded.emit(base64Array); // Отправляем массив base64 строк
          }
        };
        reader.readAsDataURL(file);
      });
    }
  }


  protected readonly uploadesIcon = uploadesIcon;
}
