import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {PageDuoServices} from "../../../services/pages/page-duo.services";
import {DuoBtnComponent} from "../duo-btn/duo-btn.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {DuoLessonComponent} from "../duo-lesson/duo-lesson.component";
import {Router} from "@angular/router";
import {windowHeight} from "../../../traits/window-tools";

@Component({
  selector: 'app-duo-chapter',
  standalone: true,
  providers: [],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    DuoBtnComponent,
    SidebarComponent,
    DuoLessonComponent,
  ],
  templateUrl: './duo-chapter.component.html',
  styleUrls: ['./duo-chapter.component.scss'],
})
export class DuoChapterComponent {
  @Input() public name: string = 'Lesson';
  @Input() public description: string = 'About art lesson ';
  @Input() public footerText: string = 'footerText';
  @Input() public imageSrc: string;
  @Input() public srcIcon: string;
  @Input() public lessons: any = [
    {
      imageSrc: 'assets/icons/duolingo/11035640.png',
      disabled: false,
      name: 'lessons 1'
    },
    {
      imageSrc: 'assets/icons/duolingo/17155284.png',
      disabled: false,
      name: 'lessons 2'
    },
    {
      imageSrc: 'assets/icons/duolingo/17155284.png',
      disabled: false,
      name: 'lessons 3'
    },
    {
      imageSrc: 'assets/icons/duolingo/17155284.png',
      disabled: false,
      name: 'lessons 4'
    },
    {
      imageSrc: 'assets/icons/duolingo/17155284.png',
      disabled: false,
      name: 'lessons 5'
    },
    {
      imageSrc: 'assets/icons/duolingo/11145432.png',
      disabled: false,
      name: 'lessons 6'
    },
  ];
  @Output() public  onSelectLesson = new EventEmitter();

  protected showLesson: boolean = false;
  protected selectLesson: any;

  constructor(public dataPage: PageDuoServices,private router: Router) {
  }



  protected openLesson(lessonItem: any) {

    this.selectLesson = lessonItem
    this.showLesson = true;
    this.onSelectLesson.emit(lessonItem)
    this.router.navigate([
      'duo/',
      this.selectLesson.id
    ], {
      queryParams: {
        name: this.selectLesson.name,
      }
    });

  }

  protected closeLesson() {
    this.selectLesson = null;
    this.showLesson = false;
  }

  protected readonly windowHeight = windowHeight;
}
