import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  brightenGradient,
  darkenGradient,
  desaturateGradient,
  opositeGradient,
  saturateGradient,
  tetradicThreeGradient
} from "../../services/common/color-tools";
import {
  getPixel,
  getPosition
} from "../../services/common/draw-methods";


@Component({
  selector: 'app-rectangle-with-color',
  templateUrl: './rectangle-with-color.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe
  ]
})
export class RectangleWithColorComponent {
  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  @Input() parentColor: string = '#b60101';
  @Input() orientation: string = 'hor';
  @Input() widthCanvas: any =300;
  @Input() heightCanvas: any  = 150;

  @Input() colors: string[] = [
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'indigo',
    'violet',
    'purple',
    'cyan',
    'magenta',
    'pink',
    'brown'
  ];
  @Input() scaleCanvas: string = '75%';
  @Input() colorsEyeDropper: string[] = [
    'red',
    'orange',
    'yellow'
  ];
  @Input() customColors: string[] = [
    'red',
    'orange',
    'yellow'
  ];
  @Input() imageSrc: string = 'assets/avatars animals/10005358.png';
  @Input() imageSize: number = 225;
  @Input() cellSize: number = 50;
  @Input() smallCircleRadius: number = 10;
  @Input() numSmallCircles: number = 50;
  @Input() numCustomCircles: number = 50;
  @Input() radiusSaturate: number = 200;
  @Input() customCircleRadius: number = 180;
  @Input() textLogo: string = 'art-club.info';
  @Input() textSize: number = 12;
  @Output() onColor = new EventEmitter<string>();

  @Output() onChange = new EventEmitter<string>();
  @Output() onGetPallete = new EventEmitter<string>();
  @Output() colorClicked = new EventEmitter<string>();
  @Output() imageSaved = new EventEmitter<void>();
  ngAfterViewInit() {
    this.draw();
  }

  drawLogo(ctx: CanvasRenderingContext2D, centerX: number, centerY: number) {
    ctx.font = `${this.textSize}px Arial`;
    ctx.fillStyle = 'white'//this.colors[0];
    // ctx.globalCompositeOperation = 'source-over'
    ctx.fillText(this.textLogo, centerX, centerY)


  }
  draw() {
    let canvas: any = document.getElementById("canvas");// this.canvas.nativeElement;

    // let canvas: any = this.canvas.nativeElement;
    const ctx: any = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const numLargeCircles = this.colors.length;

    if (this.orientation === 'hor') {

      this.drawColorGroup(ctx, saturateGradient(this.parentColor, 15), 0, 0, this.orientation)
      this.drawColorGroup(ctx, darkenGradient(this.parentColor, 15), 0, 20, this.orientation)
      this.drawColorGroup(ctx, desaturateGradient(this.parentColor, 15), 0, 40, this.orientation)
      this.drawColorGroup(ctx, brightenGradient(this.parentColor, 15), 0, 60, this.orientation)
      this.drawColorGroup(ctx, tetradicThreeGradient(this.parentColor, 15), 0, 80, this.orientation)
      this.drawColorGroup(ctx, opositeGradient(this.parentColor, 15), 0, 100, this.orientation)
    } else {
      this.drawColorGroup(ctx, saturateGradient(this.parentColor, 15), 0, 0, this.orientation)
      this.drawColorGroup(ctx, darkenGradient(this.parentColor, 15), 20, 0, this.orientation)
      this.drawColorGroup(ctx, desaturateGradient(this.parentColor, 15), 40, 0, this.orientation)
      this.drawColorGroup(ctx, brightenGradient(this.parentColor, 15), 60, 0, this.orientation)
      this.drawColorGroup(ctx, tetradicThreeGradient(this.parentColor, 15), 80, 0, this.orientation)
      this.drawColorGroup(ctx, opositeGradient(this.parentColor, 15), 100, 0, this.orientation)
    }
    this.drawLogo(ctx, this.textSize, canvas.height - 5);
    const image: string = canvas.toDataURL('image/png');
    const getColorHendler = (event)=>{
      const position =  getPosition(event);
      const colorHex  =getPixel(canvas,position);
      this.onColor.emit(colorHex)
    }
    canvas.addEventListener("click", getColorHendler);
    canvas.addEventListener("touchstart", getColorHendler);
    this.onGetPallete.emit(image);
  }

  drawColorGroup(ctx: CanvasRenderingContext2D, colors: string[], x: number, y: number, direction: any = 'hor', cellWidth: number = 15, padding: number = 5,): void {
    colors.forEach((color, index) => {
      let xPos = x;
      let yPos = y;
      if (direction === 'hor') {
        xPos += index * (cellWidth + padding); // Учитываем отступ между ячейками
      } else {
        yPos += index * (cellWidth + padding); // Учитываем отступ между ячейками
      }
      this.drawColorCell(ctx, color, xPos, yPos);
    });
  }
  drawColorCell(ctx: CanvasRenderingContext2D, color: string, x: number, y: number, wCell: number = 15): void {
    ctx.fillStyle = color;
    ctx.fillRect(x, y, wCell, wCell);
  }
  public getImage() {
    const canvas = this.canvas.nativeElement;
    const image: string = canvas.toDataURL('image/png');
    return image;
  }

}
