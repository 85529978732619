<!--<app-hammer-wrapper
  (onClick)="onClick.emit(model ?model.hex:hex); select(model)"
  (onDblclick)="onDblclick.emit(model ?model.hex:hex)"
  (onPress)="onClick.emit(model ?model.hex:hex);"
  (onTop)="onTop.emit($event)"
  (onBottom)="onBottom.emit($event)"

>-->

<!--
  [class.border-2]="model === selectModel"

[ngClass]="cd.selectModelColor == model ? 'bg-blue-500  ' : 'bg-zinc-500'"-->
<div
  (click)="onClick.emit(colorHex)"
  [style.background-color]="colorHex"
  [style.height.px]="height"
  [style.width.px]="width"
  class=" click-animation  border-zinc-100  shadow-sm  rounded-t-lg  z-10 eye-dropper-container "

>
</div>
<!--</app-hammer-wrapper>-->
