import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {EditChildrenComponent} from '../edit-children/edit-children.component';
import {Card} from '../../../interfaces/Card';
import {EditCardViewComponent} from '../edit-card-view/edit-card-view.component';
import {SiteServiceBtnComponent} from "../site-service-btn/site-service-btn.component";
import {SortableOptions} from "sortablejs";
import {SortablejsModule} from "@maksim_m/ngx-sortablejs";

@Component({
  selector: 'app-edit-list-cards',
  templateUrl: './edit-list-cards.component.html',
standalone:true,
  imports: [
    NgFor,
    NgIf,
    EditCardViewComponent,
    SiteServiceBtnComponent,
    SortablejsModule,

  ]

})
export class EditListCardsComponent {

  @Input() cards: Array<Card>;
  @Input() showFilter: boolean = false;
  @Output() onSave:EventEmitter<any> = new EventEmitter();
  public selectItem: object;
  public indexCard: number = 0;
  public maxView: number = 100;
  public selectModelCurrent: any;
  public bgColor: string ='bg-zinc-700';
  public bgImage: string ='assets/background_logo_small.png';
  public sortableOptions:SortableOptions = {
    animation: 150,
    group: 'list',
    handle: '.handle',
    onUpdate: (event: any) => {
      this.onSave.emit()
      // console.log(event);
    }
  }
  @Output() onSelect:EventEmitter<any> = new EventEmitter();

}
