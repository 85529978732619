<div class="flex items-center  text-inc-100 justify-center m-1 w-8 h-8 bg-zinc-600 border-1 border-zinc-100 rounded-lg cursor-pointer hover:bg-zinc-600 relative">
  <input
    type="file"
    (change)="onFileSelected($event)"
    [multiple]="multiple"
    class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
  />
<!--  <i [class]="uploadesIcon"></i>-->
  <!-- Иконка загрузки -->
  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v2a2 2 0 002 2h12a2 2 0 002-2v-2M4 12l8 8m0 0l8-8M12 4v16" />
  </svg>
</div>
