import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {
    NgClass,
    NgForOf,
    NgIf,
    NgStyle
} from "@angular/common";
import {PageDuoServices} from "../../../services/pages/page-duo.services";
import {PainterComponent} from "../../draw/painter/painter.component";
import {DrawZoneComponent} from "../../draw/canvas/draw-zone/draw-zone.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {
  arrowBackwardIcon,
  enterIcon,
  libsIcon,
  photosIcon,
  redoIcon,
  undoIcon,
  videoIcon
} from "../../../traits/icons-tools";
import {Router} from "@angular/router";
import {windowHeight} from "../../../traits/window-tools";
import {PinchDrawZoneComponent} from "../../draw/draw-tools/pinch-draw-zone/pinch-draw-zone.component";
import {BrushLassoComponent} from "../../draw/brush/brush-lasso/brush-lasso.component";
import {OverlayCanvasComponent} from "../../draw/draw-tools/overlay-canvas/overlay-canvas.component";
import {BrushEraseComponent} from "../../draw/brush/brush-erase/brush-erase.component";
import {BrushUndoComponent} from "../../draw/brush/brush-undo/brush-undo.component";
import {DrawCanvas} from "../../../services/konva/DrawCanvas";
import {KnvColor} from "../../../services/konva/KnvColor";
import {BrushFillProgressComponent} from "../../draw/brush/brush-fill-progress/brush-fill-progress.component";
import {GifGeneratorComponent} from "../../image/gif-generator/gif-generator.component";
import {BrushColorHistoryComponent} from "../../draw/color/brush-color-history/brush-color-history.component";
import {EyeDropperComponent} from "../../eye-dropper/eye-dropper.component";
import {PinchReferenceComponent} from "../../draw/draw-tools/pinch-reference/pinch-reference.component";
import {RectangleWithColorComponent} from "../../rectangle-with-color/rectangle-with-color.component";
import {UploadImagesComponent} from "../../draw/upload-images/upload-images.component";

@Component({
  selector: 'app-duo-lesson',
  standalone:true,
  providers:[],
  imports: [
    NgForOf,
    NgStyle,
    NgClass,
    PainterComponent,
    DrawZoneComponent,
    SidebarComponent,
    PinchDrawZoneComponent,
    BrushLassoComponent,
    OverlayCanvasComponent,
    BrushEraseComponent,
    BrushUndoComponent,
    BrushFillProgressComponent,
    GifGeneratorComponent,
    NgIf,
    BrushColorHistoryComponent,
    EyeDropperComponent,
    PinchReferenceComponent,
    RectangleWithColorComponent,
    UploadImagesComponent,
  ],
  templateUrl: './duo-lesson.component.html',
  styleUrls: ['./duo-lesson.component.scss'],
})
export class DuoLessonComponent {
  @Input() public  imageSrc: string  ='https://via.placeholder.com/1200x200';
  @Input() public  videoSrc: string;
  @Input() public name: string = 'Lesson';
  @Input() public desc: string = 'About art lesson ';
  @Input() steps: any[];
  protected reInitChroma() {
    this.showChroma = false;
    setTimeout(() => {
      this.showChroma = true;
    }, 50)
  }
  protected reInitReference() {
    this.showReference = false;
    setTimeout(() => {
      this.showReference = true;
    }, 50)
  }
  public showChroma: any = true;
  public showReference: any = true;
  public referenceSrc: any = 'assets/cinema.png';
  @Output() public  onNextLesson = new EventEmitter();
  @Output() public  onPrevLesson = new EventEmitter();
  protected showSteps: boolean =false;
  protected showInfo: boolean =false;
  protected showVideo: boolean =false;
constructor(public dataPage: PageDuoServices, public router: Router,public drawCanvas:DrawCanvas, public color:KnvColor,) {
  // this.dataPage.getSite((site:any)=>{
  //   console.log(site)
  //   this.site = site;
  // });
}

  protected startLesson() {
  // clear canvas

  }

  protected readonly videoIcon = videoIcon;
  protected readonly libsIcon = libsIcon;
  protected readonly photosIcon = photosIcon;
  protected readonly undoIcon = undoIcon;
  protected readonly redoIcon = redoIcon;
  protected readonly enterIcon = enterIcon;
  goToCourse(card: any) {

  }
  protected goToPageChapters() {
    this.router.navigate(['duo/']);
  }

  protected readonly arrowBackwardIcon = arrowBackwardIcon;
  protected readonly windowHeight = windowHeight;

  stepsMap(steps: any[]) {
  return   steps.map((item)=>item.srcPreview)
  }
}
