import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {DrawZoneComponent} from "../../canvas/draw-zone/draw-zone.component";
import { cloneModel } from 'src/app/traits/model-tools';
import {
  addToStart,
  scrollerHorizont,
  uniq
} from 'src/app/traits/array-tools';
import {ColorItemComponent} from "../../../color-item/color-item.component";

@Component({
  selector: 'app-tool-history-colors',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    ColorItemComponent,
  ],
  templateUrl: './tool-history-colors.component.html'
})
export class ToolHistoryColorsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() change: any;
  @Input() selectHistoryColor: any;
  @Input() height: any = 50;
  @Input() width: any = 50;
  @Input() margin: any = 10;
  @Input() sliceColors: any = 20;
  @Input() showFilter: any;
  @Input() view: any = 'page';
  @Input() selected: any;
  @Input() onSearchKeyword: any = '';
  @Input() models: any;
  @Input() heightList: any = 600;
  @Input() widthList: any = 80;
  @Input() heightItem: any = 100;
  @Input() showClear: any = false;
  @Input() widthItem: any = 100;
  public colors: any = [];
  @Output() onClick = new EventEmitter();
  @Output() onColor = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onPress = new EventEmitter();
  @Output() onDblclick = new EventEmitter();
  showHistoryColors: any =true

  constructor() {
  }

  ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['change'] && changes['change']?.previousValue != changes['change']?.currentValue) {
     this.addToHistory();

    }
  }

 public addToHistory(){
   // console.log(this.selectHistoryColor,'this.selectHistoryColor')
   let cloneColor = cloneModel(this.selectHistoryColor,false);
   addToStart(cloneColor, this.colors);
   let uniqColors = uniq(this.colors,'hex');
   this.colors = uniqColors.slice(0,this.sliceColors);
   console.log(uniqColors,'uniqColors')
 }
  ngOnDestroy(): void {
  }
  ngAfterViewInit() {
    this.scrollerHorizont();
  }
 scrollerHorizont(selectorContainer:string = '.scroll-items') {
    const slider = document.querySelector(selectorContainer) ?? null;
    if (slider) {
      let isDown = false;
      let startX: any;
      let scrollLeft: any;

      // @ts-ignore
      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        // @ts-ignore
        slider.classList.add('active');
        // @ts-ignore
        slider.classList.add('active');
        // @ts-ignore
        startX = e.pageX - slider.offsetLeft;
        // @ts-ignore
        scrollLeft = slider.scrollLeft;
      });
      // @ts-ignore
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        // @ts-ignore
        slider.classList.remove('active');
      });
      // @ts-ignore
      slider.addEventListener('mouseup', () => {
        isDown = false;
        // @ts-ignore

        slider.classList.remove('active');
      });
      // @ts-ignore
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) {
          return;
        }
        e.preventDefault();
        // @ts-ignore
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast

        // @ts-ignore

        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }

}
