<ul class="grid grid-cols-4  gap-1 "
    [sortablejs]="cards"
    [sortablejsOptions]="sortableOptions"
>
    <li *ngFor="let  cardItem  of  cards; let last = last;">
        <app-site-service-btn *ngIf="cardItem"
                              [name] =cardItem.name
                              [imageSrc] =cardItem.srcPreview
                              (onSelect)="onSelect.emit(cardItem)"
                              [card]="cardItem"
                              [cards]="cards"
                              [showDublicate]="true"
        />
    </li>
</ul>
