<p-tabView>
  <p-tabPanel header="TEXTS">
    <div   class="grid grid-cols-2 gap-1">
      <ng-container *ngFor="let  textBtn  of  textEditors">
        <app-edit-text-row
          [nameRow]="textBtn.name"
          [imageForColors]="card.srcPreview"
          [typeRow]="textBtn.type"
          [model]="card"
          [models]="cards"
          (onSave)="onSave.emit(card)"
        ></app-edit-text-row>
      </ng-container>
    </div>
  </p-tabPanel>
  <p-tabPanel header="PH">
    <app-box-upload
      [rowName]="'photos'"
      [model]="card"
      [view]="'grid-small'"
      (onSet)="card.srcPreview = $event"
      (onFavorite)="addToFavorite($event)"
      (onSave)="saveSite()"
    ></app-box-upload>
  </p-tabPanel>
  <p-tabPanel *ngIf="card.favorites" header="FS">
    <app-edit-array
                    [view]="'list'"
                    [model]="card"
                    [models]="card['favorites']"
                    [row]="'favorites'"
                    (onEdit)="onEdit.emit($event)"
                    (onSave)="onSave.emit(card)"
    ></app-edit-array>
  </p-tabPanel>
</p-tabView>
<button (click)="delete(card, cards);"
        class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
  <i [class]="deleteIcon" class="text-gray-300 hover:text-white"></i>
</button>
