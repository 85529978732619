import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {PaginatorModule} from "primeng/paginator";
import {
  addIcon,
  deleteIcon,
  editIcon
} from "../../../traits/icons-tools";
import {addCloneModel} from "../../../traits/array-tools";
import {Card} from "../../../interfaces/Card";
@Component({
  selector: 'app-site-service-btn',
  templateUrl: './site-service-btn.component.html',
standalone:true,
  imports: [
    NgFor,
    NgIf,
    PaginatorModule
  ]

})
export class SiteServiceBtnComponent {

  @Input() name:any ='No name';
  @Input() imageSrc:any;
  @Input() card:any;
  @Input() cards:any[];
  @Input() showMove:any = true;
  @Input() showEdit:any = true;
  @Input() showDublicate:any = false;
  @Output() onSelect:EventEmitter<any> = new EventEmitter();
  @Output() onSave:EventEmitter<any> = new EventEmitter();
  @Output() onChangeImageSrc:EventEmitter<any> = new EventEmitter();
  constructor() {
  }
  protected readonly editIcon = editIcon;
  protected readonly addIcon = addIcon;
  protected readonly addCloneModel = addCloneModel;
  protected readonly deleteIcon = deleteIcon;
}
