import {
  inject,
  Injectable
} from '@angular/core';
import {ServerSiteServices} from '../servers/server-site.services';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";
import {StaticCoursesServices} from "../static/static-courses.services";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageCoursesServices {
  public SERVER_NAME: string = 'courses';
  public DOMAIN: string = 'art-club.artas.org.ua/courses';
  public route: string = 'courses';
  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-courses.jpg';
  public premium: object = {
    name: 'courses',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'courses',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Free Online Digital Illustration Courses for Artists',
    description: 'Discover free online courses in digital illustration. Learn the basics and advanced techniques of digital drawing and create unique works of art. Courses are available for all skill levels.',
    text: 'Digital illustration offers artists incredible opportunities for creativity and self-expression. Courses in digital illustration provide both beginners and experienced professionals the chance to master new techniques and software, helping them create unique works of art.\n' + '\n' + 'For those just starting out, there are courses focused on the basics of working with graphic tablets and digital painting software. These lessons will help you understand how to properly use tools to create simple yet impressive illustrations. You will learn to work with basic elements such as lines, shapes, and colors.\n' + '\n' + 'Advanced courses for illustrators delve into the finer details of technique and offer the study of more complex aspects, such as detailed rendering, concept art creation, and character design. These lessons will allow you to refine your skills and develop a unique artistic style.\n' + '\n' + 'Free online courses in digital illustration stand out not only for their accessibility but also for the variety of topics they cover. This allows everyone to choose the right direction, whether it’s cartoon style, photorealism, or fantasy art. You can study at a convenient time and at your own pace, making the process of acquiring new knowledge comfortable and effective.\n' + '\n' + 'When choosing the right course, it’s important to consider your skill level and learning goals. Whether you want to learn the basics or dive deep into details, there’s always an option that suits your needs. Many courses offer practical assignments that will help solidify your knowledge and improve your skills. Additionally, don’t forget about free resources and communities where you can get extra support and share experiences with other artists.',
    h1: 'Free Online Digital Illustration Courses for Artists',
    h2: 'Variety of Free Digital Illustration Courses',
    h3: 'Courses for Beginner Artists',
    h4: 'Examples of Courses for Different Skill Levels',
    h5: 'Free Resources and Communities for Artists',
    textEndPage: 'Discover free online courses in digital illustration. Learn the basics and advanced techniques of digital drawing and create unique works of art. Courses are available for all skill levels.',
    keywords: [
      'free digital illustration courses',
      'online courses for artists',
      'free digital drawing',
      'training courses for illustrators',
      'digital art lessons',
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  public getSite(callback: any = null) {
    if (this.fromServer) {
      return this.getSiteFromServer(callback);
    } else {
      return this.getSiteStatic(callback);
    }

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    if (this.fromServer) {

      this.site = site
      // this.downloadSiteBtn(this.site);
      // let server: any = inject(ServerSiteServices)
      this.server.saveSite(this.DOMAIN, this.site);
    }
  }

  getSiteStatic(callback) {
    this.site = inject(StaticCoursesServices).site;
    callback && callback(this.site)
    return this.site;


  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer(callback: any = null) {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: any): void => {
      this.site = responseSite;
      callback && callback(responseSite)
    });
    return this.site;
  }

  getCards() {
    if (this.fromServer) {

    } else {
      return this.site.organizer.messenges;
    }
  }

  findCard(slug: any) {
    let cards = this.getCards();
    let findCard = cards.filter((itemProduct: any) => {
      if (itemProduct.id === slug) {
        return itemProduct;
      }
    });
    console.log(findCard)

    return findCard[0];
  }
}
