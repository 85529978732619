import {Injectable, Input} from '@angular/core';
import {InterfaceTools} from './InterfaceTools';
import {KnvProject} from './KnvProject';
import {addToStart} from "../../traits/array-tools";

@Injectable({
  providedIn: 'root',
})


export class BrushesToolsServices {
  public brushesClientLib: any = [];
  public brushesImageFavorites: any = [];
  public showBrushesScroll: any = false;
  public showBrushesLib: any = false;
  public showBrushesHistory: any = false;
  public showBrushesSizes: any = false;
  public showBrushesColors: any = false;
  public showSmallBrushTools: any = false;
  public showBrushesTools: any = true;

  public selectImageBrush: any = {name: '01.png', type: 'imageBrush', value: '01.png', srcPreview: 'assets/brush-strokes/01.png'};

  public brushesColors: any = [
    {name: 3, hex: '#4b4a4a'},
    {name: 4, hex: '#000000'},
    {name: 5, hex: '#802f2f'},
    {name: 6, hex: '#808000'},
    {name: 7, hex: '#2e8e2e'},
    {name: 8, hex: '#2e9a9a'},
    {name: 9, hex: '#222282'},
    {name: 10, hex: '#872c87'},
  ];
  public brushesList: any = [
    {
      name: 'opacity-brush',
      srcPreview: 'assets/icons/opacity-brush.png',
      type: 'opacity-brush',
      class: 'social',
      globalCompositeOperation: 'normal',
      lineJoin: 'round',
      opacity: 5,
      // layerIndex: this.project.layerBrushIndex,
    },
    {
      name: 'hard-brush',
      srcPreview: 'assets/icons/hard-brush.png',
      type: 'hard-brush',
      class: 'social',
      globalCompositeOperation: 'normal',
      lineJoin: 'round',
      opacity: 10,
      // layerIndex: this.project.layerBrushIndex,
    },
    /*{
      name: 'stroke-brush',
      srcPreview: 'assets/icons/stroke-brush.png',
      type: 'stroke-brush',
      class: 'social',
      mode: 'normal',
      lineJoin: 'miter',
      opacity: 0.1,
      layerIndex: this.project.layerBrushIndex,
    },*/
  ];
  public brushOpacity: any = [
    {name: '0', value: 0},
    {name: '1', value: 1},
    {name: '2', value: 2},
    {name: '3', value: 3},
    {name: '4', value: 4},
    {name: '5', value: 5},
    {name: '6', value: 6},
    {name: '8', value: 8},
    {name: '9', value: 9},
    {name: '100', value: 100},
  ];
  public brushSizesListSmall: any = [
    {name: '0.2', value: 0.2},
    {name: '0.5', value: 0.5},
    {name: '1', value: 1},
    {name: '2', value: 2},
    {name: '3', value: 3},
    {name: '4', value: 4},
    {name: '5', value: 5},
    {name: '6', value: 6},
    {name: '7', value: 7},
    {name: '8', value: 8},
    {name: '10', value: 10},
    {name: '15', value: 15},
    {name: '20', value: 20},
  ];
  public brushSizesListLarge: any = [
    {name: '20', value: 20},
    {name: '20', value: 20},
    {name: '30', value: 30},
    {name: '40', value: 40},
    {name: '50', value: 50},
    {name: '60', value: 60},
    {name: '75', value: 75},
    {name: '100', value: 100},
    {name: '120', value: 100},
    {name: '150', value: 150},
    {name: '200', value: 200},
    {name: '250', value: 250},
    {name: '300', value: 300},
    {name: '500', value: 500},
  ];
  public brushesMenu: any = [
    /*{
  name: 'simpleDen',
  type: 'shapeBrush',
  value: 'simpleDen',
  icon: 'fa-solid fa-paintbrush',
  srcPreview: 'assets/brush-strokes/12.png',
},*/
    /* {
       name: 'simpleHard',
       type: 'shapeBrush',
       value: 'simpleHard',
       icon: 'fa-solid fa-paintbrush',
       srcPreview: 'assets/brush-strokes/12.png',
     },*/
    /* {
       name: 'simpleSoft',
       type: 'shapeBrush',
       value: 'simpleSoft',
       icon: 'fa-solid fa-paintbrush',
       srcPreview: 'assets/brush-strokes/02.png',
     },*/
    /*{
      name: 'rectangle',
      type: 'shapeBrush',
      value: 'rectangle',
      icon: 'fa-solid fa-paintbrush',
      srcPreview: 'assets/brush-strokes/04.png',
    },*/
    // {name: 'arc', type: 'shapeBrush', value: 'arc', icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/brush-strokes/16.png',},
    /*{
  name: 'lassoBrush',
  type: 'lassoBrush',
  value: 'lassoBrush',
  icon: 'fa-solid fa-paintbrush',
  srcPreview: 'assets/brush-strokes/12.png',
},*/
    /*{
        name: 'smoogleBrush',
        type: 'smoogleBrush',
        value: 'smoogleBrush',
        icon: 'fa-solid fa-paintbrush',
        srcPreview: 'assets/brush-strokes/12.png',
    },*/
    // {name: 'arc-half', value: 'arc-half', icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'arc-half', value: 'arc-half', icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'gradient', value: 'gradient', icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'imgBrush', value: 'imgBrush', icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'svg',  value: 'svg',icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'strokeShadow',  value: 'strokeShadow',icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'chrome',  value: 'chrome',icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'web',  value: 'web',icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'sketchy',  value: 'sketchy',icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'strokeFur',  value: 'strokeFur',icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},
    // {name: 'longFur',  value: 'longFur',icon: 'fa-solid fa-paintbrush', srcPreview: 'assets/params/images/fill-brush.png',},

  ];
  public brushesImageList: any = [
    {name: '01.png', type: 'brushImage', value: '01.png', srcPreview: 'assets/brush-strokes/01.png'},
    {name: '02.png', type: 'brushImage', value: '02.png', srcPreview: 'assets/brush-strokes/02.png'},
    {name: '03.png', type: 'brushImage', value: '03.png', srcPreview: 'assets/brush-strokes/03.png'},
    {name: '04.png', type: 'brushImage', value: '04.png', srcPreview: 'assets/brush-strokes/04.png'},
    {name: '05.png', type: 'brushImage', value: '05.png', srcPreview: 'assets/brush-strokes/05.png'},
    {name: '06.png', type: 'brushImage', value: '06.png', srcPreview: 'assets/brush-strokes/06.png'},
    {name: '07.png', type: 'brushImage', value: '07.png', srcPreview: 'assets/brush-strokes/07.png'},
    {name: '08.png', type: 'brushImage', value: '08.png', srcPreview: 'assets/brush-strokes/08.png'},
    {name: '09.png', type: 'brushImage', value: '09.png', srcPreview: 'assets/brush-strokes/09.png'},
    {name: '10.png', type: 'brushImage', value: '10.png', srcPreview: 'assets/brush-strokes/10.png'},
    {name: '11.png', type: 'brushImage', value: '11.png', srcPreview: 'assets/brush-strokes/11.png'},
    {name: '12.png', type: 'brushImage', value: '12.png', srcPreview: 'assets/brush-strokes/12.png'},
    {name: '13.png', type: 'brushImage', value: '13.png', srcPreview: 'assets/brush-strokes/13.png'},
    {name: '14.png', type: 'brushImage', value: '14.png', srcPreview: 'assets/brush-strokes/14.png'},
    {name: '15.png', type: 'brushImage', value: '15.png', srcPreview: 'assets/brush-strokes/15.png'},
    {name: '16.png', type: 'brushImage', value: '16.png', srcPreview: 'assets/brush-strokes/16.png'},
    {name: '17.png', type: 'brushImage', value: '17.png', srcPreview: 'assets/brush-strokes/17.png'},

  ];
  public modeInkList: any = [
    {name: 'N', value: 'normal', icon: 'fa-solid fa-tag'},
    {name: 'L', value: 'lighten', icon: 'fa-solid fa-tag'},
    {name: 'O', value: 'overlay', icon: 'fa-solid fa-tag'},
    {name: 'D', value: 'Darken', icon: 'fa-solid fa-tag'},
    {name: 'C', value: 'color', icon: 'fa-solid fa-tag'},
  ];
  public brushesListSketch: any = [
    {
      name: 'sketch-brush',
      srcPreview: 'assets/icons/sketch-brush.png',
      // type: 'sketch-brush',
      class: 'social',
      globalCompositeOperation: 'normal',
      lineJoin: 'round',
      size: 5,
      color: 'black',
      opacity: 5,
      type: 'opacity-brush',

      // layerIndex: this.project.stage.layerSketchIndex,
    },
    {
      name: 'middle-brush-sketch',
      srcPreview: 'assets/icons/middle-brush-sketch.png',
      class: 'social',
      globalCompositeOperation: 'normal',
      lineJoin: 'round',
      opacity: 2,
      size: 10,
      type: 'opacity-brush',

      // layerIndex: this.project.stage.layerSketchIndex,
    },
    {
      name: 'hard-brush-sketch',
      srcPreview: 'assets/icons/hard-brush-sketch.png',
      type: 'hard-brush',
      class: 'social',
      globalCompositeOperation: 'normal',
      lineJoin: 'round',
      opacity: 10,
      // layerIndex: this.project.stage.layerSketchIndex,
    },
    {
      name: 'hard-brush-sketch',
      srcPreview: 'assets/icons/fill-brush.png',
      class: 'social',
      globalCompositeOperation: 'normal',
      lineJoin: 'round',
      size: 25,
      opacity: 10,
      type: 'opacity-brush',

      // layerIndex: this.project.stage.layerSketchIndex,
    },
  ];
  public erasesList: any = [
    {
      name: 'erase-brush',
      srcPreview: 'assets/icons/erase-soft-brush.png',
      type: 'opacity-brush',
      class: 'social',
      globalCompositeOperation: 'destination-out',
      lineJoin: 'round',
      opacity: 3,
      size: 50,

    },
    {
      name: 'erase-brush',
      srcPreview: 'assets/icons/erase-brush.png',
      type: 'opacity-brush',
      class: 'social',
      globalCompositeOperation: 'destination-out',
      lineJoin: 'round',
      opacity: 10,
      size: 20,

    }
  ];
  public modeAllList: any = [
    {name: 'normal', value: 'normal', icon: 'fa-solid fa-tag'},
    {name: 'color', value: 'color', icon: 'fa-solid fa-tag'},
    {name: 'source-over', value: 'source-over', icon: 'fa-solid fa-tag'},
    {name: 'source-in', value: 'source-in', icon: 'fa-solid fa-tag'},
    {name: 'source-out', value: 'source-out', icon: 'fa-solid fa-tag'},
    {name: 'source-atop', value: 'source-atop', icon: 'fa-solid fa-tag'},
    {name: 'source-atop', value: 'source-atop', icon: 'fa-solid fa-tag'},
    {name: 'destination-over', value: 'destination-over', icon: 'fa-solid fa-tag'},
    {name: 'destination-in', value: 'destination-in', icon: 'fa-solid fa-tag'},
    {name: 'destination-out', value: 'destination-out', icon: 'fa-solid fa-tag'},
    {name: 'destination-atop', value: 'destination-atop', icon: 'fa-solid fa-tag'},
    {name: 'copy', value: 'copy', icon: 'fa-solid fa-tag'},
    {name: 'xor', value: 'xor', icon: 'fa-solid fa-tag'},
    {name: 'multiply', value: 'multiply', icon: 'fa-solid fa-tag'},
    {name: 'screen', value: 'screen', icon: 'fa-solid fa-tag'},
    {name: 'overlay', value: 'overlay', icon: 'fa-solid fa-tag'},
    {name: 'darken', value: 'darken', icon: 'fa-solid fa-tag'},
    {name: 'lighten', value: 'lighten', icon: 'fa-solid fa-tag'},
    {name: 'color-dodge', value: 'color-dodge', icon: 'fa-solid fa-tag'},
    {name: 'color-burn', value: 'color-burn', icon: 'fa-solid fa-tag'},
    {name: 'hard-light', value: 'hard-light', icon: 'fa-solid fa-tag'},
    {name: 'soft-light', value: 'soft-light', icon: 'fa-solid fa-tag'},
    {name: 'difference', value: 'difference', icon: 'fa-solid fa-tag'},
    {name: 'exclusion', value: 'exclusion', icon: 'fa-solid fa-tag'},
    {name: 'hue', value: 'hue', icon: 'fa-solid fa-tag'},
    {name: 'saturation', value: 'saturation', icon: 'fa-solid fa-tag'},
    {name: 'luminosity', value: 'luminosity', icon: 'fa-solid fa-tag'},
  ];
  private showBackgroundColors: boolean = false;

  constructor(
    public  interfaceTools: InterfaceTools,
    public project: KnvProject,
  ) {

  }

  public addBrushToClientLib(brushItem:any){
    // this.cd.array.addToStart(brushItem,this.cd.brushesClientLib)
    // cd.array.add(brushImageItem,konvaTools.draw.brushesImageFavorites,true);

    addToStart(brushItem,this.brushesImageFavorites)
  }
  public isShowBrushesHistory(){
    return this.showBrushesHistory  == true;
  }
  public isHideBrushesHistory(){
    return this.showBrushesHistory  ==false;
  }
  public onShowBrushesHistory(){
    this.showBrushesHistory  =true;
  }
  public onHideBrushesHistory(){
    this.showBrushesHistory  =false;
  }
  public isShowBrushesLib(){
    return this.showBrushesLib  == true;
  }
  public isHideBrushesLib(){
    return this.showBrushesLib  ==false;
  }
  public onShowBrushesLib(){
     this.showBrushesLib  =true;
  }
  public onHideBrushesLib(){
    this.showBrushesLib  =false;
  }
  public onShowBrushesSizes(){
     this.showBrushesSizes  =true;
  }
  public onHideBrushesSizes(){
     this.showBrushesSizes  =false;
  }
  public isShowBrushesSizes(){
    return this.showBrushesSizes  ==true;
  }
  public isHideBrushesSizes(){
    return this.showBrushesSizes  ==false;
  }
  public onShowBrushesColors(){
     this.showBrushesColors  =true;
  }
  public onHideBrushesColors(){
     this.showBrushesColors  =false;
  }
  public isShowBrushesColors(){
    return this.showBrushesColors  ==true;
  }
  public isHideBrushesColors(){
    return this.showBrushesColors  ==false;
  }


  public onShowBackgroundColors(){
    this.showBackgroundColors  =true;
  }
  public onHideBackgroundColors(){
    this.showBackgroundColors  =false;
  }
  public isShowBackgroundColors(){
    return this.showBackgroundColors  ==true;
  }
  public isHideBackgroundColors(){
    return this.showBrushesColors  ==false;
  }

  public onShowSmallBrushTools(){
   this.showSmallBrushTools =true;

  }
  public onHideSmallBrushTools(){
     this.showSmallBrushTools  ==false;
  }

  public isBrushSelect(brushItem:any){
    return  brushItem == this.selectImageBrush;
  }

  public setBrushBlur(blur: any = 10) {
    /*this.project.stage.brushLayerSketch.blur = blur;
    this.interfaceTools.showHandMove = false;
    this.srcToImageBrushParams();
    // this.interfaceTools.onEnableBrush();
    // this.interfaceTools.onHandMoveStop();
    this.interfaceTools.onHideEyeDropper();*/

  }
  public setBrushOpacities(opacity: any = 10) {
    // this.project.stage.brushLayerSketch.opacity = opacity;
    // this.interfaceTools.showHandMove = false;
    // this.srcToImageBrushParams();
    // // this.interfaceTools.onEnableBrush();
    // // this.interfaceTools.onHandMoveStop();
    // this.interfaceTools.onHideEyeDropper();

  }

}
