
<button *ngIf="historyBrushList.length > 0"
        class="click-animation h-[40px] w-[40px] p-1 bg-zinc-700 text-zinc-200"
        (click)="brushesTools.onShowBrushesHistory()">
  <i [class]="undoIcon"></i>
  <span class="text-[20px]">{{historyBrushList.length}}</span>
</button>

<app-sidebar [show]="brushesTools.isShowBrushesHistory()"
             (onSave)="brushesTools.onHideBrushesHistory()"
             [showClose]="true"
             [height]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'bottom'"
>
  <ng-container *ngIf="brushesTools.isShowBrushesHistory()">
    <div class=" p-1 m-1 grid grid-cols-4 gap-1 ">
      <ng-container *ngFor="let historyItem of  historyBrushList;">
        <div class="item-scroll">
          <button
            class=" click-animation bg-zinc-200 rounded-t-lg block  m-[2px] p-[2px] flex flex-row   text-center text-[10px] truncate relative border-solid border-1 border-yellow-200  "
            (click)="
                     drawCanvas.restoreState(historyItem.srcStroke);
                     brushesTools.onHideBrushesHistory()"
          >
            <img class="h-8 w-8" [src]="historyItem.srcStroke" alt="srcStroke">
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</app-sidebar>




