import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  deleteIcon,
  downloadIcon,
  listIcon,
  saveIcon,
  shareIcon
} from "../../traits/icons-tools";
import {
  addToStart,
  deleteFromArray
} from "../../traits/array-tools";
import {ListDownloadsComponent} from "../list-downloads/list-downloads.component";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {ShareImageComponent} from "../share-image/share-image.component";
import {modelNew} from "../../traits/model-tools";
import {dateNow} from "../../traits/datetime-tools";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {saveFile} from "../../traits/file-tools";
import {BadgeModule} from "primeng/badge";
import {CoinsComponent} from "../coins/coins.component";
import {CdServices} from "../../services/common/cd.services";
import {AlbumComponent} from "../album/album.component";

@Component({
  selector: 'app-btn-download',
  templateUrl: './btn-download.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
    ListDownloadsComponent,
    SidebarComponent,
    ShareImageComponent,
    OverlayPanelModule,
    BadgeModule,
    CoinsComponent,
  ]


})
export class BtnDownloadComponent {
  @Input() models: object[] = [];
  @Input() srcImage: string;
  @ViewChild('shareComponent') shareComponent: ElementRef<HTMLCanvasElement>;
  @ViewChild('coins') coins: ElementRef<HTMLCanvasElement>;
  imageResult: string =null;
  addParent: boolean =false;
  @Input() showLib: boolean =false;
  @Input() showShare: boolean =true;
  public listDownloads: string[] = [];
  public listDownloadsCount: any = 0;
  public showDownloadsList: boolean = false;
  public showSharedComponent: boolean = true;
  @Input() disabled: boolean =true;
  @Input() showList: boolean =false;
  @Input() showCoin: boolean =false;
  @Input()  textBtn: string = 'Download';

  @Output() onShared = new EventEmitter();
  @Output() onDownload = new EventEmitter();
  protected readonly deleteIcon:string = deleteIcon;
  protected readonly shareIcon:string = shareIcon;
  protected readonly listIcon:string = listIcon;
  protected readonly downloadIcon:string = downloadIcon;
  constructor(public cd:CdServices) {
  }
  hideButtonTemporarily(target: HTMLButtonElement, duration: number = 2000) {
    target.hidden = true;
    setTimeout(() => {
      target.hidden = false;
    }, duration);
  }
  saveResultToDB(base64: string) {
    this.imageResult = base64;
    this.addParent = true;
    setTimeout(() => {
      this.addParent = false;
      this.imageResult = null;
    }, 500)
    /*model*/
    /*add to first stages*/

  }
  public showShareImage(target: any) {
    this.onDownload.emit(this.srcImage)
    this.hideButtonTemporarily(target, 4000)
    this.resetSharedComponent()
    // @ts-ignore
    this.shareComponent.shareImage();
  }
  resetSharedComponent(callback: any = null) {
    this.showSharedComponent = false;
    setTimeout(() => {
      this.showSharedComponent = true;
    })
  }
  public downloadImage(srcImage:string) {
    this.onDownload.emit(srcImage)

   /* // this.hideButtonTemporarily(target)
    // @ts-ignore
    this.roundPallete.downloadImage()
    // this.getPalleteImage();*/
    let modelDelete: any = modelNew();
    modelDelete.srcPreview = srcImage;
    addToStart(modelDelete, this.listDownloads);
    this.listDownloadsCount = this.listDownloads.length;

    // console.log(this.srcImage)
this.downloadToDevice(modelDelete.srcPreview)
  }
  public fileName(){
    return `art-club.info (pallete-${dateNow()}).png`;
  }
  public downloadToDevice(srcImage: string) {
    function downloadImage(url, filename) {
      // Загружаем изображение по URL
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          // Создаем объект URL из полученного Blob
          var objectUrl = URL.createObjectURL(blob);

          // Создаем ссылку для скачивания изображения
          var link = document.createElement('a');
          link.href = objectUrl;
          link.download = filename;

          // Добавляем ссылку на страницу (но она не будет отображаться)
          document.body.appendChild(link);

          // Эмулируем клик по ссылке для скачивания
          link.click();

          // Удаляем ссылку из DOM
          document.body.removeChild(link);

          // Очищаем объект URL
          URL.revokeObjectURL(objectUrl);
        });

    }
    downloadImage(srcImage, this.fileName());
    this.textBtn = 'save on device...';
    setTimeout(()=>{this.textBtn = 'Download'},2000)
  }

  protected readonly saveIcon = saveIcon;
}
