<div class="bg-zinc-50">


    <app-pinch-reference>
      <div [style.border]="'5px solid ' + drawCanvas.getBrushColor() +' '"
           [style.background-color]="drawCanvas.getBrushColor()"
      >
        <div class="absolute top-1 left-1 z-20">
          <app-upload-images [multiple]="false"
                             (imagesLoaded)="setReferenceHandler($event);
                                             reInitReference()"
          />
        </div>
      <app-brush-color-history (onColor)="reInitChroma()"/>

      <app-eye-dropper *ngIf="showReference"
        [widthImage]="300"
        [heightImage]="300"
        [src]="referenceSrc"
        (onColor)="
                   drawCanvas.setBrushColorHex($event);reInitChroma();
                   color.triggerColorsInOtherTools($event,
                      true,
                      true,
                      true,
                      false
                      );
                  "
      ></app-eye-dropper>
      <app-rectangle-with-color
        *ngIf="(showChroma)"
        [orientation]="'hor'"
        [widthCanvas]="300"
        [heightCanvas]="135"
        [parentColor]="drawCanvas.getBrushColor()"
        (onColor)="drawCanvas.setBrushColorHex($event);
         color.triggerColorsInOtherTools($event,
                      true,
                      true,
                      true,
                      false)
"
      />
      </div>
    </app-pinch-reference>
<!--    <app-brushes-lib/>-->
<!--    <app-brush-size/>-->
<!--    <app-brush-color/>-->
<!--    <app-brush-erase/>-->
<!--    <app-brush-undo [changeAdd]="drawCanvas.isMouseUpCounter"/>-->
<!--    <app-draw-download/>-->
<!--    <app-draw-clear/>-->
<!--  <app-brush-lasso/>-->
<!--  <app-overlay-canvas [imageSrc]="'assets/cinema.png'"/>-->
 <!-- <app-brush-fill-progress [change]="drawCanvas.filledPixels"
                           [maxFilledPixels]="1000"
  />-->
  <app-pinch-draw-zone>
    <app-draw-zone/>
  </app-pinch-draw-zone>
<!--  <app-background-color/>-->
<!--<app-record-video-canvas/>-->
  <!--  <app-brush-cursor/>-->
  <!--  <app-draw-compare/>-->
  <!--  <app-brush-smudge/>-->
</div>
<!--  <app-images-tabs-canvas-top></app-images-tabs-canvas-top>-->

<!--  <app-color-panel-canvas-top></app-color-panel-canvas-top>-->

<!--  <app-tools-panel-canvas-left></app-tools-panel-canvas-left>-->

<!--  <app-brush-panel-canvas-right></app-brush-panel-canvas-right>-->
<!--  <app-brush-lib-panel-canvas-bottom></app-brush-lib-panel-canvas-bottom>-->


<!--<app-konva-zone
  (onRenderBeforeStage)="onRenderBeforeStage()"
  (onRenderAfterStage)="onRenderAfterStage()"
>
</app-konva-zone>-->

<!--<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>-->
