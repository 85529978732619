import { Injectable } from "@angular/core";
import {modellayersGroup, setBrushLayerSketch, setImageLayer, setRectangleLayer} from './konva-tools';
import {
  sizeWindow,
  windowWidth
} from "src/app/traits/window-tools";
import {windowHeight} from "../../traits/window-tools";
import {randomId} from "../../traits/string-tools";
@Injectable({
  providedIn: 'root',
})


export class KnvProject  {
  public sizeDrawZone:any = sizeWindow()
  public height:any = windowHeight();
  public width:any = windowWidth();
  public base64Clear: any = 'assets/params/images/clear-bg.png';
  public srcPreview: any = 'http://server.artas.org.ua/Fotozona/1.png';
  public name:any =  'draw-compositor';
  public layerIndex:any = 5;
  public stage:any = {
    id: randomId(),
    createAt: Date.now(),
    name: 'draw-compositor',
    srcPreview: 'http://server.artas.org.ua/Fotozona/1.png',// this.cd.base64Clear,
    rectangleLayer: setRectangleLayer(),
    imageLayer: setImageLayer(this.base64Clear),
    groupLayer: modellayersGroup(
      [],
    ),
    brushLayerSketch: setBrushLayerSketch({mode: 'normal'}),
    params: {
      class: 'render-zone konva-container',
      container: 'render-zone',
      mode: 'draw',
      width: this.width,
      height: this.height,
      draggable: false,
      backgroundColor: '#5f1b1b',
    },
  };
  public selectLayerModel:any =  {};
  public newStage() {
    return {
      id:  randomId(),
      createAt: Date.now(),
      name: 'new-stage',
      srcPreview: this.srcPreview,// this.cd.base64Clear,
      rectangleLayer: setRectangleLayer(),
      imageLayer: setImageLayer(this.base64Clear),
      groupLayer: modellayersGroup(
        [],
      ),
      brushLayerSketch: setBrushLayerSketch({mode: 'normal'}),
      params: {
        class: 'render-zone konva-container',
        container: 'render-zone',
        mode: 'draw',
        width: windowWidth(),
        height: windowHeight(),
        draggable: false,
        backgroundColor: '#5f1b1b',
      },
    };
  }



}
