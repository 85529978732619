import {
  Component,
  inject,
  OnInit
} from '@angular/core';
import {ClientPatreonAuthServices} from '../../../services/client/client-patreon-auth.services';
import {
  NgFor,
  NgIf
} from "@angular/common";
import {arrowOneDownIcon} from "../../../traits/icons-tools";
import {PatreonEmailEnterComponent} from "../../client/patreon/patreon-email-enter/patreon-email-enter.component";
import { CdServices } from 'src/app/services/common/cd.services';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from "@angular/router";
import {filter} from "rxjs";

@Component({
  selector: 'app-navbar-client',
  templateUrl: './navbar-client.component.html',
  standalone:true,
  imports: [NgIf,NgFor,PatreonEmailEnterComponent]
})
export class NavbarClientComponent implements OnInit{
constructor(
  public cd:CdServices,
  public client:ClientPatreonAuthServices,
  private router: Router, private activatedRoute: ActivatedRoute
) {

}

  protected readonly arrowOneDownIcon = arrowOneDownIcon;


  ngOnInit() {

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   const fragment = this.activatedRoute.snapshot.fragment;
    //   if (fragment === 'login') {
    //     console.log('Навигация до формы входа');
    //     if(!this.cd.logined) {this.cd.loginForm = true}
    //   }
    // });
  }
}
