import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {InterfaceTools} from "../../../../services/konva/InterfaceTools";
import {KnvColor} from "../../../../services/konva/KnvColor";
import {ToolHistoryColorsComponent} from "../tool-history-colors/tool-history-colors.component";

@Component({
  selector: 'app-brush-color-history',
  standalone: true,
  providers: [],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    ToolHistoryColorsComponent,
  ],
  templateUrl: './brush-color-history.component.html',

})
export class BrushColorHistoryComponent implements OnInit {
  public innerHeight: any = window.innerHeight;
  public innerWidth: any =300// window.innerWidth;
  showHistoryBar: any = true;
  @Output() onColor = new EventEmitter();

  constructor(
    public drawCanvas: DrawCanvas,
    public interfaceTools: InterfaceTools, public color: KnvColor,) {
  }

  ngOnInit() {
  }

}
