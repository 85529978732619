import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NgIf} from '@angular/common';
@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',

  standalone:true,
  imports: [
    NgIf,
  ]


})
export class CameraComponent   {

  @ViewChild('video') videoElement: ElementRef;
  @Output() base64Output: EventEmitter<string> = new EventEmitter<string>();
  loading: boolean = true;
  frontCamera: boolean = false;
  streamingToBase64: boolean = false;
  private stream: MediaStream;

  async ngOnInit(): Promise<void> {
    try {
      await this.startCapture();
      this.streamingToBase64 = true;
      this.toggleStreamToBase64()

    } catch (error) {
      console.error('Ошибка при запуске видеопотока:', error);
    }
  }
ngOnDestroy(){
  this.streamingToBase64 = false;
this.toggleStreamToBase64()
}
  async startCapture(): Promise<void> {
    try {
      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: this.frontCamera ? 'user' : 'environment'
        }
      };
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      const videoElement: HTMLVideoElement = this.videoElement.nativeElement;
      videoElement.srcObject = this.stream;
      videoElement.onloadedmetadata = () => {
        this.loading = false;
      };
    } catch (error) {
      console.error('Ошибка при запуске видеопотока:', error);
      throw error;
    }
  }

  toggleCamera(): void {
    this.loading = true;
    this.frontCamera = !this.frontCamera;
    this.startCapture();
  }

  takeSnapshot(): void {
    const video = this.videoElement.nativeElement;
    const canvas = document.createElement('canvas');
    canvas.width = 300// video.videoWidth;
    canvas.height = 300 // video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    const base64String = canvas.toDataURL('image/jpeg', 0.5);
    this.base64Output.emit(base64String);
  }

  toggleStreamToBase64(): void {

    if (this.streamingToBase64) {
      this.streamToBase64();
    }
  }

  private streamToBase64(): void {
    const video = this.videoElement.nativeElement;
    const canvas = document.createElement('canvas');
    canvas.width = 300;// video.videoWidth;
    canvas.height = 300;// video.videoHeight;
    const context = canvas.getContext('2d');
    setInterval(() => {
      if (this.streamingToBase64) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const base64String = canvas.toDataURL('image/jpeg', 0.5);
        this.base64Output.emit(base64String);
      }
    }, 500); // Отправлять каждую секунду
  }
}
