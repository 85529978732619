import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgStyle
} from "@angular/common";
import {PageDuoServices} from "../../../services/pages/page-duo.services";

@Component({
  selector: 'app-duo-btn',
  standalone:true,
  providers:[],
  imports: [
    NgForOf,
    NgStyle,
    NgClass,
  ],
  templateUrl: './duo-btn.component.html',
  styleUrls: ['./duo-btn.component.scss'],
})
export class DuoBtnComponent {
  @Input() name:string = 'name btn'
  @Input() srcIcon:string
  @Input()  disabled: boolean = false;
  @Output() onClick = new EventEmitter();


constructor(public dataPage: PageDuoServices) {
  // this.dataPage.getSite((site:any)=>{
  //   console.log(site)
  //   this.site = site;
  // });
}
ngAfterViewInit(){

}
}
