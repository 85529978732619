import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BrushesToolsServices} from "../../../../services/konva/brushes-tools.services";
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {InterfaceTools} from "../../../../services/konva/InterfaceTools";
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {
  brushesListIconImage,
  brushHardIconImage,
  filterIconImage
} from "../../../../traits/iconsImages.services";
import {SidebarComponent} from "../../../common/sidebar/sidebar.component";
import {
  closeIcon,
  colorHistoryIcon,
  colorLibIcon,
  eyeDropperIcon,
  palleteIcon,
  palleteLibIcon
} from "../../../../traits/icons-tools";
import {scrollParams} from "../../../../traits/window-tools";
import {ToolChromaColorsComponent} from "../../color/tool-chroma-colors/tool-chroma-colors.component";
import {ToolColorHarmonyComponent} from "../../color/tool-color-harmony/tool-color-harmony.component";
import {ToolIroColorsZoomMinusComponent} from "../../color/tool-iro-colors-zoom-minus/tool-iro-colors-zoom-minus.component";
import {KnvColor} from "../../../../services/konva/KnvColor";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {countFilledPixels} from "../../../../services/common/draw-methods";

@Component({
  selector: 'app-brush-lasso',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    SidebarComponent,
  ],
  templateUrl: './brush-lasso.component.html',
})
export class BrushLassoComponent implements OnInit {
  @Input() items: any;
  @Output() onCollection = new EventEmitter();

  constructor(
    public drawCanvas:DrawCanvas,
  ) {

  }

  ngOnInit() {
  }

  drawLasso() {
    const canvas:any = this.drawCanvas.canvasBrush; //document.getElementById('canvas');
    const ctx:any = canvas.getContext('2d');

    // Параметры инструмента
    let drawing = false;
    let points = [];
    /*'rgba(255, 215, 0, 1)'*/
    const fillColor = this.drawCanvas.getBrushColor() ; // Цвет заливки
    let shapes = []; // Массив для хранения нарисованных фигур

    // Функция для начала рисования
    function startDrawing(x, y) {
      drawing = true;
      points = [{ x, y }];
    }

    // Функция для рисования линии в процессе
    const drawLine = (x, y)=> {
      if (this.drawCanvas.enableLasso)return;

      if (!drawing) return;

      points.push({ x, y });

      // Очищаем временный слой
      // ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Перерисовываем все сохраненные фигуры
      // redrawAllShapes();

      // Рисуем текущую залитую фигуру
      if (points.length > 2) {
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
          ctx.lineTo(points[i].x, points[i].y);
        }
        ctx.lineTo(points[0].x, points[0].y);
        ctx.closePath();
        ctx.fillStyle = this.drawCanvas.getBrushColor();
        ctx.fill();
      }

      // Рисуем текущую линию от последней точки к новой
      ctx.beginPath();
      ctx.moveTo(points[points.length - 2].x, points[points.length - 2].y);
      ctx.lineTo(x, y);
      ctx.strokeStyle = this.drawCanvas.getBrushColor();
      ctx.stroke();
      countFilledPixels(
        this.drawCanvas.canvasBrush,
        x,
        y,
        this.drawCanvas.filledPixels,
        this.drawCanvas.maxFilledPixels,
        (pixels:number)=>{ this.drawCanvas.filledPixels =pixels }
      );
    }

    // Функция для завершения рисования
    const endDrawing = () => {
      if (!drawing) return;
      drawing = false;

      // Добавляем завершенную фигуру к массиву
      if (points.length > 2) {
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
          ctx.lineTo(points[i].x, points[i].y);
        }
        ctx.closePath();
        ctx.fillStyle = this.drawCanvas.getBrushColor();;
        ctx.fill();
        shapes.push([...points]);  // Сохраняем фигуру
      }
      points = [];
    }

    // Перерисовка всех сохраненных фигур
    const redrawAllShapes = ()=> {
      for (const shape of shapes) {
        ctx.beginPath();
        ctx.moveTo(shape[0].x, shape[0].y);
        for (let i = 1; i < shape.length; i++) {
          ctx.lineTo(shape[i].x, shape[i].y);
        }
        ctx.closePath();
        ctx.fillStyle = this.drawCanvas.getBrushColor();;
        ctx.fill();
      }
    }
    // Обработчики событий для мыши
    canvas.addEventListener('mousedown', (e) => startDrawing(e.offsetX, e.offsetY));
    canvas.addEventListener('mousemove', (e) => drawLine(e.offsetX, e.offsetY));
    canvas.addEventListener('mouseup', endDrawing);
    canvas.addEventListener('mouseleave', () => {
      drawing = false;
      points = [];
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      redrawAllShapes();
    });

    // Обработчики сенсорных событий для мобильных устройств
    canvas.addEventListener('touchstart', (e) => {
      const touch = e.touches[0];
      const rect = canvas.getBoundingClientRect();
      startDrawing(touch.clientX - rect.left, touch.clientY - rect.top);
      e.preventDefault();
    });

    canvas.addEventListener('touchmove', (e) => {
      const touch = e.touches[0];
      const rect = canvas.getBoundingClientRect();
      drawLine(touch.clientX - rect.left, touch.clientY - rect.top);
      e.preventDefault();
    });

    canvas.addEventListener('touchend', endDrawing);

  }



  ngAfterViewInit() {
    this.drawLasso()
  }



  protected readonly brushHardIconImage = brushHardIconImage;
  protected readonly filterIconImage = filterIconImage;
}
