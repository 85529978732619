
<!--absolute top-[250px] -20  -->
<button
  *ngIf="drawCanvas.isErase()"
  (click)=" drawCanvas.setBrushMode()"
  class="click-animation h-[40px] w-[40px] p-1 ztext-2xl bg-zinc-700 text-zinc-200"
>
  <img
    [src]="brushHardIconImage"
    alt="Erase soft"
    class=""
  >
 <!-- <small class="text-center text-[10px] font-bold">
            <span>
              Brush
            </span>
  </small>-->
</button>
<!-- && drawCanvas.isNotClear()-->
<button
  *ngIf="drawCanvas.isNotErase()"
  (click)=" drawCanvas.setErase()"
  class="click-animation h-[40px] w-[40px] p-1    text-2xl bg-zinc-700 text-zinc-200"
>
  <img
    [src]="eraseHardIconImage"
    alt="Erase soft"
    class=""
  >
 <!-- <small class="text-center text-[10px] font-bold">
            <span>
              Erase
            </span>
  </small>-->
</button>

