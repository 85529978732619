

<!---->
<button
    *ngIf="drawCanvas.enableBrush"
  (click)=" drawCanvas.onDisableBrush() ;drawCanvas.onEnableLasso()"
  class="click-animation h-[40px] w-[40px] p-1 text-2xl bg-zinc-700 text-zinc-200"
>
  <img
    [src]="filterIconImage"
    alt="Erase soft"
    class=""
  >

</button>
<button
  *ngIf="drawCanvas.enableLasso"
  (click)=" drawCanvas.onDisableLasso();drawCanvas.onEnableBrush()"
  class="click-animation h-[40px] w-[40px] p-1 text-2xl bg-zinc-700 text-zinc-200"
>
  <img
    [src]="brushHardIconImage"
    alt="Erase soft"
    class=""
  >
</button>
