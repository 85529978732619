import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {InteractTools} from "../../../../services/common/interact-tools";
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {
  windowHeight,
  windowWidth
} from "../../../../traits/window-tools";


@Component({
  selector: 'app-pinch-draw-zone',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
  ],
  templateUrl: './pinch-draw-zone.component.html',
  styleUrls: ['./pinch-draw-zone.component.scss'],

})
export class PinchDrawZoneComponent implements AfterViewInit {
  // @ts-ignore
  @ViewChild('accessId') accessId: ElementRef;
  // @ts-ignore
  @ViewChild('gestureArea') gestureArea: ElementRef;
  // @ts-ignore
  @ViewChild('scaleElement') scaleElement: ElementRef;

  @Input() public width: any = windowWidth();
  @Input() public height: any = windowHeight();

  constructor(
    public drawCanvas:DrawCanvas,
  ) {
  }
  ngAfterViewInit() {
    // this.zoomDragPinch();
  }
  protected zoomDragPinch() {
    const startDrag = ()=>{
      this.drawCanvas.isPinching = true
      // this.drawCanvas.onDisableBrush()
      // this.drawCanvas.onDisableLasso()
    }
    const stopDrag = ()=>{
      this.drawCanvas.isPinching = false

      // this.drawCanvas.onEnableBrush();
      // this.drawCanvas.onEnableLasso();
    }

    let interactTools = new InteractTools();
    interactTools.multiTouch(
      this.gestureArea.nativeElement,
      this.scaleElement.nativeElement,
      startDrag,
      stopDrag
    );
    /*  interactTools.draggable(
        stopDrag
      );*/

  }
}
