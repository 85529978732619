import {cloneModel} from "./model-tools";
declare const _;
export function getIndex(m: any, ms: any[]): number {
  if (ms) {
    return ms.indexOf(m);
  }
}
export function getIndexLast(ms:any[])
{
  return ms.length-1;
}
export function deleteItem(m: any, ms: any[]): void {
  ms.splice(this.i(m, ms), 1);
}
export function deleteFromArray(m: any, ms: any[]) {
 return  ms.splice(getIndex(m, ms), 1);
}

export function addCloneModel(model: any, models: any) {
  let modelClone = cloneModel(model, true);
  addToStart(modelClone,models)

}
export function addToStart(m: any, ms:any[] = [], unic: boolean = true) {
  return unic ? !ms.includes(m) ? ms.unshift(m) : false : ms.unshift(m);
}


export function addToEnd(m: any, ms: any[] = [], unic: any = true) {
  return unic ? !ms.includes(m) ? ms.push(m) : false : ms.push(m);
}
export function addToEndNotExits(m: any, ms: any[] = []) {
  if (ms) {
    addToEnd(m, ms);
  } else {
    ms = [];
    addToEnd(ms,ms);
  }
}

export  function clear(ms: any[]):any[] {
 return  ms = [];
}
export  function  chunk(ms: any[],size:number = 5):any[] {
 return  _.chunk(ms, size);
}
export function filterHidden(ms: any[] = []): any[] {
  return ms.filter((item: any): boolean => {
    return item.sw == false;
  });
}
export function filterShowed(ms: any[] = []): any[] {
  return ms.filter((item: any): boolean => {
    return item.sw == true;
  });
}
export function countHidden(ms: any[] = []):number{
  return countAll(filterHidden(ms));
}
export function countShowed(ms: any[] = []):number{
  return countAll(filterShowed(ms));
}

export function countAll(ms: any[]): number {
  return ms.length;
}
export  function getIndexArray(m: any, ms: any) {
  if (ms) {
    return ms.indexOf(m);
  }
}
export function scrollerHorizont(selectorContainer:string = '.scroll-items') {
  const slider = document.querySelector(selectorContainer) ?? null;
  if (slider) {
    let isDown = false;
    let startX: any;
    let scrollLeft: any;

    // @ts-ignore
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      // @ts-ignore
      slider.classList.add('active');
      // @ts-ignore
      slider.classList.add('active');
      // @ts-ignore
      startX = e.pageX - slider.offsetLeft;
      // @ts-ignore
      scrollLeft = slider.scrollLeft;
    });
    // @ts-ignore
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      // @ts-ignore
      slider.classList.remove('active');
    });
    // @ts-ignore
    slider.addEventListener('mouseup', () => {
      isDown = false;
      // @ts-ignore

      slider.classList.remove('active');
    });
    // @ts-ignore
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) {
        return;
      }
      e.preventDefault();
      // @ts-ignore
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast

      // @ts-ignore

      slider.scrollLeft = scrollLeft - walk;
    });
  }
}
export  function uniq(array: any, row: any = 'categoryName') {
  let uniqArray = _.uniqBy(array, row);
  return _.remove(uniqArray, (el: any) => {
    return el.name !== '';
  });
  /*return    array.reduce((acc:any, cur:any) => [
       ...acc.filter((obj:any) => obj.id !== cur.id), cur
     ], []);*/
  // return array.reduce((acc:any, current:any) => {
  //   current = current.trim();
  //   if (!acc.includes(current)) {
  //     acc.push(current);
  //   }
  //   return acc;
  // }, []);
}
