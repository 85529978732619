import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgStyle
} from "@angular/common";

@Component({
  selector: 'app-scroll-wrapper',
  standalone:true,
  providers:[],
  imports: [
    NgForOf,
    NgStyle,
    NgClass,
  ],
  templateUrl: './scroll-wrapper.component.html',
  styleUrls: ['./scroll-wrapper.component.scss'],
})

export class ScrollWrapperComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollContainer', { static: true }) scrollContainer!: ElementRef;

  // Уникальный идентификатор для хранения позиции прокрутки
  @Input() scrollId!: string;

  private get uniqueScrollId(): string {
    // Проверяем, передан ли scrollId или сохранен ранее
    if (this.scrollId) {
      return this.scrollId;
    } else {
      // Проверяем, есть ли сохраненный ID в localStorage
      const storedId = localStorage.getItem('scrollId');
      if (storedId) {
        return storedId;
      }

      // Если нет, генерируем новый, сохраняем его и возвращаем
      const newId = 'scroll-' + Math.random().toString(36).substring(2, 15);
      localStorage.setItem('scrollId', newId);
      return newId;
    }
  }

  ngAfterViewInit(): void {
    // Восстановление прокрутки при загрузке
    const savedScrollPosition = localStorage.getItem(this.uniqueScrollId);
    if (savedScrollPosition) {
      this.scrollContainer.nativeElement.scrollTop = parseInt(savedScrollPosition, 10);
    }

    // Слушаем событие прокрутки
    this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  ngOnDestroy(): void {
    this.scrollContainer.nativeElement.removeEventListener('scroll', this.onScroll);
  }

  // Сохранение позиции прокрутки с использованием уникального scrollId
  private onScroll(): void {
    localStorage.setItem(this.uniqueScrollId, this.scrollContainer.nativeElement.scrollTop.toString());
  }
}
