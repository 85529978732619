import {
  Component,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from "@angular/common";
import {dateNow} from "../../../traits/datetime-tools";
import {ClipboardModule} from "ngx-clipboard";
import {ServerSiteServices} from "../../../services/servers/server-site.services";
import {ToggleButtonModule} from "primeng/togglebutton";
import {FormsModule} from "@angular/forms";
import {CdServices} from "../../../services/common/cd.services";
import {BrowserCloseAlertComponent} from "../../browser-close-alert/browser-close-alert.component";
import {cloneModel} from "../../../traits/model-tools";
import {
  copyIcon,
  downloadIcon,
  saveIcon
} from "../../../traits/icons-tools";

@Component({
  selector: 'app-site-debuger',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    ClipboardModule,
    JsonPipe,
    ToggleButtonModule,
    BrowserCloseAlertComponent
  ],
  templateUrl: './site-debuger.component.html',


})
export class SiteDebugerComponent implements OnInit {
  @Input() data: any;


  constructor(public cd:CdServices) {
  }

  ngOnInit() {

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.data.site.domain + '_' + dateNow() + '_.txt')
  }
  public saveSite() {
    if(this.enabledAutosave) {

      this.data.saveSite(this.data.site)
      console.log(this.data.site,'saveSite')
    }
  }
  public enabledAutosave: any =true;
  protected readonly downloadIcon = downloadIcon;
  protected readonly copyIcon = copyIcon;
  protected readonly saveIcon = saveIcon;
}
