import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  NgFor,
  NgIf
} from '@angular/common';

import {ColorsLibsServices} from '../../../services/colors-libs.services';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {color} from "../../../traits/image-tools";

@Component({
  selector: 'app-colors-grid',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ScrollPanelModule
  ],
  templateUrl: './colors-grid.component.html',

})
export class ColorsGridComponent implements OnInit {
  @Input() nameRow: any = 'name';
  @Input() typeRow: any = 'text';
  @Input() imageForColors: any;
  @Input() showImageColors: boolean = false;
  @Input() cards: any = [];
  @Input() colors: any = [];
  @Input() outputColors: any = [];
  @Input() similar: any = [];
  @Output() onFavorite = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() onGetFirstColor = new EventEmitter();

  constructor(public colorsLibs: ColorsLibsServices) {

  }

  public ngOnInit() {
    if(this.showImageColors){
      this.getImageColors()
    }else{
      this.colors =  this.outputColors.concat(this.colorsLibs.colorsAll())
    }
  }

  public getImageColors() {
    if (this.imageForColors) {
      color(this.imageForColors, (colorsImage: any[]) => {
        // console.log(colorsImage)
        this.colorsLibs.colorsAll()
        this.colors = colorsImage.concat(this.colors);
        this.onGetFirstColor.emit(this.colors[0])

      })
    }
  };

  select(colorHex: string) {
    this.onClick.emit(colorHex)

  }
}
