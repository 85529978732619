<!--<div   style="height:25px; overflow: hidden"
       class="absolute top-0 seo-container text-center z-0">
  <h1>{{dataPage.seoTexts.h1}}</h1>
  <h2>{{dataPage.seoTexts.h2}}</h2>
  <h3>{{dataPage.seoTexts.h3}}</h3>
  <p>{{dataPage.seoTexts.text}}</p>
  <p>{{dataPage.seoTexts.textEndPage}}</p>
</div>-->

<!--@if(site){
  <app-common-home
    [site]="site"
    [dataPage]="dataPage"
  ></app-common-home>
}-->
<div class="bg-zinc-800 p-1 h-full w-full  rounded-lg shadow-lg"  [style.height.px]="windowHeight()">
  <app-scroll-wrapper scrollId="test">
    <ng-container *ngFor="let chapterItem of dataPage.getCards(site) ">
      <app-duo-chapter
        [name]="chapterItem.name"
        [description]="chapterItem.desc"
        [srcIcon]="chapterItem.srcIcon"
        [imageSrc]="chapterItem.srcPreview"
        [lessons]="chapterItem.array"
      />
    </ng-container>
  </app-scroll-wrapper>
</div>
<!--<div class="bg-white p-10 rounded-lg shadow-lg">

  &lt;!&ndash; div перед змейкой &ndash;&gt;
  <div class="mb-8 text-center">
    <p class="text-lg text-gray-700 bg-gray-700">Это div перед змейкой</p>
  </div>

  &lt;!&ndash; Змейка &ndash;&gt;
  <div id="snake-container" class="flex flex-col items-center">
    <div *ngFor="let element of snakeElements; let i = index"
         class="w-16 h-16 text-white flex justify-center items-center rounded-full shadow-lg mb-8"
         [ngClass]="element.bgColor"
         [ngStyle]="{'margin-left': getMarginLeft(i)}">
      {{ 'START' }}
    </div>
  </div>
  <div class="mb-8 text-center bg-gray-700">
    <p class="text-lg text-gray-700">Это div перед змейкой</p>
  </div>
</div>-->


