@if(password  === '2525'){
  <div class="p-3 m-2">
  <app-site-services-list
    (onSelect)="select.data = $event"
  ></app-site-services-list>
  </div>
  <app-site-service-select #select
  ></app-site-service-select>
}@else{
  <p-password [(ngModel)]="password" [feedback]="false"></p-password>

}





<!--{{this.selectCard|json}}
<app-edit-list-feed
  [cards]="selectSite.organizer.messenges"
  (onSelect)="this.selectCard = $event"
></app-edit-list-feed>-->

<!--      <app-edit-btn *ngIf="cd.showEditAll === true"
                    [modelCurrent]="item"
                    [modelsCurrent]="this.site.organizer.messenges"
                    [modelParent]="this.site.organizer"
      ></app-edit-btn>-->
<!-- scroll-cards <app-edit-btn *ngIf="cd.showEditAll === true"
                               [modelCurrent]="cardItem"
                               [modelsCurrent]="cards"
                               [modelParent]="this.site.organizer"
                 ></app-edit-btn>-->
