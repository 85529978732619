
<app-array-panel-tools
  [model]="model"
  [listName]="row"
  [modelName]="'model'"
  (onView)="view = $event"
></app-array-panel-tools>


<ng-container *ngIf="model && model[row]">
  <p-scrollPanel [style]="{height:250}">
    <ng-container *ngIf="view === 'list'">
      <ul class="grid grid-cols-1  gap-1 "
        [sortablejs]="model[row]"
        [sortablejsOptions]="sortableOptions"
      >
        <li *ngFor="let  listItem  of model[row];let first = first;let number  = index">
          <app-site-service-btn *ngIf="listItem"
                                [name] =listItem.name
                                [imageSrc] =listItem.srcPreview
                                (onSelect)="onEdit.emit({children:listItem,childrens:model[row]})"
                                [showTools] =false
                                [card]="listItem"
                                [cards]="model[row]"
                                [showDublicate]="true"

          />
<!--          <div style="margin-bottom: 5px!important; margin-top: 5px!important;">
            <div class="p-inputgroup">
                 <span class="p-inputgroup-addon handle">
                     <i [class]="'fas fa-arrows-alt'"></i>
                 </span>
              <span class="p-inputgroup-addon">

                    <ng-container *ngIf="rows">
<div class="grid grid-cols-2 gap-1">

                    <ng-container *ngFor="let  rowName  of rows">
                      <app-edit-text-row
                        [minHeight]="50"
                        [nameRow]="rowName"
                        [model]="listItem"
                        [models]="models"
                      ></app-edit-text-row>
                    </ng-container>
    <div class=" flex justify-around text-[15px] font-bold mb-1 text-gray-300">
      <span>
        <i [class]="deleteIcon" (click)="delete(listItem,model[row])"></i>
      </span>
      <span>
        <i [class]="editIcon" (click)="selectCard = listItem"></i>
      </span>
       <button (click)="addCloneModel(listItem,model[row]); onSave.emit()"
               class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
          <i [class]="addIcon" class="text-gray-300 hover:text-white"></i>
        </button>
    </div>
            </div>
                    </ng-container>
                    <ng-container *ngIf="!rows">
                                  <div class="">
&lt;!&ndash;                     <img [src]="listItem"
                          class=" m-1 p-1 w-[75px] h-[75px] rounded-xl  border-1 border-zinc-100 handle ">&ndash;&gt;
                                 <textarea
              style="padding: 2px!important;font-size: 16px !important; "
              pInputTextarea
              [placeholder]="listItem.name"
              [(ngModel)]="listItem.name"
    ></textarea>
                      <div class=" flex justify-around text-[15px] font-bold mb-1 text-gray-300">
                        <span (click)="delete(listItem,model[row])">
&lt;!&ndash;                          <i [class]="theme.icon.delete" ></i>&ndash;&gt;
                        </span>
                      </div>
                                  </div>
                    </ng-container>
                </span>
            </div>
          </div>-->
        </li>
      </ul>
    </ng-container>
  </p-scrollPanel>
</ng-container>

   <!-- <ng-container *ngIf="view === 'grid'">
      &lt;!&ndash;<div class="grid grid-cols-2 gap-1"
           [sortablejs]="model[row]"
           [sortablejsOptions]="cd.sortableOptions('list')"
           (sort)="cd.changeDetect = true;"
           #copyContainer2
      >
        <ng-container *ngFor="let  listItem  of model[row];let first = first;let number  = index">
          <ng-container *ngIf="rows">
            <ng-container *ngFor="let  rowName  of rows.slice(0,1)">
              <app-tool-text-row-edit
                [nameRow]="rowName"
                [model]="listItem"
                [models]="models"
              ></app-tool-text-row-edit>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!rows">
            <div class="">
              <img [src]="listItem"
                   class=" m-1 p-1 w-[75px] h-[75px] rounded-xl  border-1 border-zinc-100 handle ">
              <div class=" flex justify-around text-[15px] font-bold mb-1 text-gray-300"><span><i
                [class]="cd.theme.icon.delete" (click)="delete(listItem,model[row])"></i></span></div>
            </div>
          </ng-container>

        </ng-container>
      </div>&ndash;&gt;
    </ng-container>
  </p-scrollPanel>
</ng-container>-->
<!--<span class="p-inputgroup-addon"
               >{{number + 1}}</span>-->

<!-- <span   class="p-inputgroup-addon">
   &lt;!&ndash;<img (click)="selectItem(listItem)"
        class="rounded-circle "
        width="40"
        height="40"
        [src]="!listItem.srcPreview?cd.testImage.src:listItem.srcPreview"
        alt="list-photo"
   >&ndash;&gt;
 </span>-->
<!--    <span (click)="cd.toImage(listItem,'small')"  class="p-inputgroup-addon"
    >
<i class="fas fa-x-ray"></i>
</span>-->

<!--  <span *ngIf="this.cd.showModelEdit" class="p-inputgroup-addon"
  >
       <i [class]="cd.theme.icon.add" (click)="addTag()"></i>
  </span>-->


<!-- <span *ngIf="this.cd.showModelEdit" class="p-inputgroup-addon" style="font-size: 10px!important; padding: 0!important; height: 25px; min-width: 1px!important;"
       [style.background-color]="listItem.color"
       (click)="cd.selectModel = listItem; cd.showColorLib = !cd.showColorLib; "
 ></span>-->


<!-- <span *ngIf="(!cd.array.first(listItem,model[row]) && this.cd.showModelEdit)"
   class="p-inputgroup-addon" (click)=" cd.array.moveStart(listItem,model[row])">
   <i class="fas fa-angle-double-up"></i>
 </span>-->


<!--<span *ngIf="(!cd.array.first(listItem,model[row]) &&  this.cd.showModelEdit)"
      class="p-inputgroup-addon" (click)=" cd.array.moveUp(listItem,model[row])">
 <i class="fas fa-level-up-alt"></i>
</span>-->


<!-- </span>
   <span class="p-inputgroup-addon">
   <p-toggleButton onIcon="fas fa-check-square" offIcon="far fa-square"
                   [(ngModel)]="listItem.check"></p-toggleButton>
 </span>-->
<!-- <span
   class=p-inputgroup-addon
   ngxClipboard
   (cbOnSuccess)=" cd.array.copyText(listItem,'name',true)"
   [cbContent]="listItem.name"
   [container]="copyContainer2">

  <i class="far fa-copy"></i>
</span>-->


<!--<span *ngIf="model[row].length>1 && this.cd.showModelEdit"
      style="font-size: 10px!important; padding: 0!important; height: 25px;"
      class="p-inputgroup-addon"
      (click)="deleteTag(listItem)">
    <i [class]="cd.theme.icon.delete"></i>
</span>-->


<!--   <span
     (click)="cd.array.toArchive(listItem);" class=p-inputgroup-addon>
     <i class="fas fa-archive"></i>
 </span>-->
<!--   <span *ngIf="(!cd.array.last(listItem,model[row]) && this.cd.showModelEdit)" class="p-inputgroup-addon"
     (click)=" cd.array.moveDown(listItem,model[row])">
      <i class="fas fa-level-down-alt"></i>
   </span>-->


<!-- <span *ngIf="(!cd.array.last(listItem,model[row])&& this.cd.showModelEdit)" class="p-inputgroup-addon"
   (click)=" cd.array.moveEnd(listItem,model[row])">
 <i class="fas fa-angle-double-down"></i>
  </span>-->
<!-- <div style=" height:20px!important; padding:0;margin-top: 0;" class="p-inputgroup">
               &lt;!&ndash;          <app-tool-tags [model]="listItem"></app-tool-tags>&ndash;&gt;
             </div>-->
