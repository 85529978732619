<div
     class="absolute z-30 inset-0 flex items-center justify-center"
     [style.opacity.%]="opacity"
     [style.mix-blend-mode]="mode"
     [style.pointer-events]="lockDraw"
>
<!--  <app-zoom-image>-->
    <app-sketch-converter
           [imageSrc]="imageSrc"
      [blurAmount]="blurAmount"
      [colorHex]="selectColor"
      (sketchGenerated)="imageSrcSketch = $event">
    </app-sketch-converter>
<!--  </app-zoom-image>-->
</div>
