  <app-sidebar [show]="showSelectCard"
               (onSave)="showSelectCard = false"
               [showClose]="true"
               [width]="100"
               [height]="100"
               [ext]="'%'"
               [zIndex]="10000"
               [position]="'bottom'"
  >
    @if (data && showSelectCard){
    <app-site-debuger #debuger
      [data]="data"
    ></app-site-debuger>
  <app-edit-parent
    [card]="card"
    [cards]="cardsMessenges"
    #select
    (onSave)="debuger.saveSite()"
  ></app-edit-parent>
<!--      <app-edit-card-select
        [card]="card"
        [cards]="cardsAdditions"
        #select
        (onSave)="debuger.saveSite()"
      ></app-edit-card-select>-->
    }


  </app-sidebar>
  @if (data){
     <app-edit-list-cards
 [cards]="data.site.organizer.messenges"
 (onSelect)="onSelectCard($event)"
></app-edit-list-cards>
 <!-- <app-edit-list-cards
    [cards]="this.data.site.additions.messenges"
    (onSelect)="onSelectCard($event)"
  ></app-edit-list-cards>-->

}
