import {
  textDecrypt,
  textEncrypt
} from "./crypt-tools";
import {
  cloneModel,
  modelNew
} from "./model-tools";
import {dateNow} from "./datetime-tools";
import {addToStart} from "./array-tools";

export  function importBin(event: any, callback: any  = null) {
  const files = event.target.files;
  if (files.length === 0) return;
  const file = files[0];
  // @ts-ignore
  let reader = new FileReader();
  reader.onload = (e:any) => {
    let decryptedText: string = textDecrypt(e.target.result);
    // @ts-ignore
    let stageModel: any = JSON.parse(decryptedText);
    stageModel = cloneModel(stageModel, true)
    callback && callback(stageModel)
  };
  reader.readAsText(file);
}
export  function importImage(event: any,callback: any  = null ) {
  const files = event.target.files;
  const base64Strings = [];
  const intervalTime = 1000;

  // @ts-ignore
  Array.from(files).forEach((file:any, index) => {
    // @ts-ignore
    setTimeout(() => {
      // @ts-ignore
      const reader = new FileReader();
      reader.onloadend = ()=> {
        const base64String:any = reader.result;
        // @ts-ignore
        base64Strings.push(base64String);
        callback && callback(base64String)
        // @ts-ignore
        if (base64Strings.length === files.length) {

        }
      };

      reader.readAsDataURL(file);
    }, intervalTime * index);
  });
}

export  function  objectToString(objectJson: any) {
  return JSON.stringify(objectJson);
}

export  function downloadJson(jsonObject: object,fileName:string = 'fileName') {
  let siteText: string = objectToString(jsonObject);
  saveTextFile(siteText, fileName)
}

export function saveTextFile(text: any, filename: any) {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}


export function randomFileName(){
  return `art-club.info-${dateNow()}).png`;
}
export function downloadImage(srcImage:string,filename:string = randomFileName() ) {
  fetch(srcImage)
    .then(response => response.blob())
    .then(blob => {
      let objectUrl = URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = objectUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    });
}
export function exportTextToBin(textToEncrypt: string, fileName: string) {
  let encrypted: string = textEncrypt(textToEncrypt);
  let blob = new Blob([encrypted], {type: "application/octet-stream"});
  let a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = `${fileName}.bin`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
export  function exportStage(selectStage: any) {
  let siteText: string = objectToString(selectStage);
  let fileName: string = selectStage.name + '_stage_' + dateNow();
  exportTextToBin(siteText, fileName);
  // this.exportTextFile(siteText ,fileName );

}
