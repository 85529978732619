import {Injectable} from '@angular/core';

import {
  base64ToImageData,
  clearCanvas,
  getContext,
  imageDataToSrcStroke,
  makeCursor,
  putImageData,
  setBackgroundCanvas
} from '../common/canvas-tools';
import {
  angleBetween,
  brushArc,
  brushImage,
  countFilledPixels,
  distanceBetween,
  getPixel,
  getPosition,
  isCanvasBlank,
  srcToStroke
} from '../common/draw-methods';
import {downloadImage} from "../../traits/importExport-tools";
import {
  windowHeight,
  windowWidth
} from "../../traits/window-tools";

@Injectable({
  providedIn: 'root',
})

export class DrawCanvas {
  public isPinching = false;
  public isDragging = false;
  public enableEyeDropper: any = false;
  public clearCanvasZone: any = true;
  public enableBrush: any = true;
  public enableLasso: any = false;
  public enableSmudge: any = false;
  public isDraggable: any = true;
  public enableErase: any = true;
  public filledPixels: any = 0;
  public maxFilledPixels: any = 10000;
  public TYPE_BRUSH: any = 'type-brush';
  public TYPE_ERASE: any = 'type-erase';
  public OPACITY_10: any = 10;
  public OPACITY_5: any = 5;
  public MODE_ERASE: any = 'destination-out';
  public MODE_BRUSH: any = 'normal';
  public MODE_INK: any = 'lighten';
  public positionBrush: any;
  public canvasBrush: any;
  public brushLassoSwitch: any =false;
  public canvasBackground: any;
  public isDrawing: any;
  public isMouseMoveCounter: number = 0;
  public isMouseDownCounter: number = 0;
  public isMouseUpCounter: number = 0;
  protected srcBrushStroke: any = 'assets/brush-strokes/01.png';
  protected brushName: any = 'brushImage';
  protected brushType: any = 'brushArc';
  protected srcStroke: any = 'assets/params/images/clear-bg.png';
  protected size: any = 20;
  protected colorBrushHex: any = '#884545';
  protected colorBackgroundHex: any = '#2d2d2d';
  protected alpha: any = 1;
  protected opacity: any = 1;
  protected blur: any = 1;
  protected globalCompositeOperation: any = 'normal';
  protected context: any;
  protected dist: any;
  protected angle: any;
  protected imgObjectStroke: any;
  protected lastPoint: any;
  protected clickPosition: any;
  protected paddingPlus: any = 10;//10
  protected paddingMinus: any = 25;//25
  protected iteration: any = 3;//3

  constructor() {

  }

  public defaultPainterParams(brushColor: string = this.getBrushColor(),
                              backgroundColor:string=this.getBackgroundColor()
  ) {
    // this.setBrushColorHex(brushColor);
    // this.setBackgroundColor(backgroundColor);
  }

  public getCanvasBrush() {
    const canvasBrush = document.getElementById('canvas-brush');
    return this.setCanvasPrams(canvasBrush);
  }

  public getCanvasBackground() {
    const canvasBackground = document.getElementById('canvas-background');
    return this.setCanvasPrams(canvasBackground);
  }

  public setCanvasPrams(canvas: any) {
    canvas.width = windowWidth();
    canvas.height = windowHeight();
    console.log(canvas.width)
    console.log(canvas.width)
    return canvas;
  }

  public init() {
    this.defaultPainterParams()
    this.canvasBrush = this.getCanvasBrush();
    this.canvasBackground = this.getCanvasBackground();
    this.context = getContext(this.canvasBrush);
    this.setImageBrushParams();
    this.events();
  }

  public getJpg(params: any = {
    pixelRatio: 1,
    quality: 1
  }) {
    return this.canvasBrush.toDataURL(params);
  }

  public isClear() {
    return this.clearCanvasZone === true;
  }

  public setBackgroundColor(colorHEx: string = this.getBackgroundColor()) {
    this.colorBackgroundHex = colorHEx;
    setBackgroundCanvas(this.canvasBackground, colorHEx);
  }

  public onEnableBrush() {
    this.enableBrush = true;
  }

  public onDisableBrush() {
    this.enableBrush = false;
  }

  public onEnableLasso() {
    this.enableLasso = true;
  }

  public onDisableLasso() {
    this.enableLasso = false;
  }

  public onEnableSmudge() {
    this.enableSmudge = true;
  }

  public onDisableSmudge() {
    this.enableSmudge = false;
  }

  public onEnableDraggable() {
    this.isDraggable = true;
  }

  public onDisableDraggable() {
    this.isDraggable = false;
  }

  public isEnableBrush() {
    return this.enableBrush == true;
  }

  public isDisableBrush() {
    return this.enableBrush == false;
  }

  public isNotClear() {
    return this.clearCanvasZone === false;
  }

  public onNotClear() {
    this.clearCanvasZone = isCanvasBlank(this.canvasBrush);
  }

  public isEnabledEyeDropper() {
    return this.enableEyeDropper == true;
  }

  public isDisabledEyeDropper() {
    return this.enableEyeDropper == false;
  }

  public onEnableEyeDropper() {
    this.enableBrush = false;
    this.enableErase = false;
    this.enableEyeDropper = true;
    this.setBrushMode();
  }

  public onDisableEyeDropper() {
    this.enableBrush = true;
    this.enableErase = true;
    this.enableEyeDropper = false;
  }

  public getBrushColor() {
    return this.colorBrushHex;
  }

  public getBackgroundColor() {
    return this.colorBackgroundHex;
  }

  public getBrushStroke() {
    return this.srcBrushStroke;
  }

  public getBrushSize() {
    return this.size;
  }

  public clearCanvasAlert() {
    this.clearCanvas();
    /*this.confirmationService.confirm({
      message: 'Clear Canvas?',
      header: 'Confirm Clear?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

      },
    });*/
  }

  public onDestroy() {
    this.clearCanvas();
  }

  public setBrushColorHex(colorHex: any) {
    this.colorBrushHex = colorHex;
    this.setImageBrushParams();

  }

  public setBrushType(type: any = 'erase-hard') {
    this.brushType = type;
  }

  public isHardBrush() {
    /*konvaTools.draw.statusBrushDraw &&*/
    // return this.brushName === 'simpleSoft';
    return false;
  }

  public isSoftBrush() {
    /*konvaTools.draw.statusBrushDraw &&*/
    // return this.brushName === 'simpleHard';
    return false;

  }

  public isNotErase() {

    /*konvaTools.draw.statusBrushDraw &&*/
    // return this.brushName === 'simpleHard';
    return this.getMode() === this.MODE_BRUSH;


  }

  public isErase() {
    // konvaTools.draw.statusBrushDraw && konvaTools.draw.getMode() == konvaTools.draw.ERASE_MODE

    /*konvaTools.draw.statusBrushDraw &&*/
    // return this.brushName === 'simpleHard';
    // konvaTools.draw.statusBrushDraw && konvaTools.draw.getMode() !== konvaTools.draw.ERASE_MODE;
    return this.getMode() === this.MODE_ERASE;

  }

  public getMode() {
    return this.globalCompositeOperation;
  }

  public setMode(globalCompositeOperation: any = 'normal') {
    this.globalCompositeOperation = globalCompositeOperation;
  }

  public setHardBrushBtn() {
    this.setBrushType(this.TYPE_BRUSH);
    this.setMode(this.MODE_BRUSH);
    this.setBrushHard();
  }

  public setBrushHard() {
    this.brushType = 'simpleHard';
    // this.interfaceTools.onReInitBrushPreview();
    // this.interfaceTools.showHandMove = false;
    // this.srcToImageBrushParams();
    // this.interfaceTools.onEnableBrush();
    // this.interfaceTools.onHideEyeDropper();
  }

  public setBrushSoft() {
    this.brushType = 'simpleSoft'; // {name: 'simpleSoft', value: 'simpleSoft'};
    // this.project.stage.brushLayerSketch.srcPreview = '/assets/icons/hard-brush-sketch.png';
    // this.interfaceTools.onReInitBrushPreview();
    // this.interfaceTools.showHandMove = false;
    // this.srcToImageBrushParams();
    // this.interfaceTools.onEnableBrush();
    // this.interfaceTools.onHideEyeDropper();
  }

  public setSoftBrushBtn() {
    this.setBrushType('soft');
    this.setBrushSoft();
  }

  public setBrushMode() {
    this.setMode(this.MODE_BRUSH);
  }

  public setErase() {
    this.setMode(this.MODE_ERASE);
  }

  public setOpacity(opacity: any = 10) {
    this.opacity = opacity;
  }

  public setEraseHard() {
    this.setMode(this.MODE_ERASE);
    this.setOpacity(this.OPACITY_10);
    this.setStrokeNew();
    // this.interfaceTools.onReInitBrushPreview();

  }

  public setStrokeNew() {
    let canvas = this.canvasBrush;
    this.setImageDataToSrcStroke(canvas);
  }

  public setBrushName(brushName: any = 'brushImage') {
    this.brushName = brushName;
  }

  public setBrushSrc(srcPreview: any = 'brushImage') {
    this.srcBrushStroke = srcPreview;
  }

  public onSetBrushSize(size: any = 50) {
    this.size = size;
    this.setImageBrushParams();
    // console.log(size);
    // this.project.stage.brushLayerSketch.size = size;
    // this.interfaceTools.onReInitBrushPreview();
    // this.interfaceTools.showHandMove = false;
    // this.srcToImageBrushParams();
    // this.interfaceTools.onEnableBrush();
    // this.onHandMoveStop();
    // this.interfaceTools.onHideEyeDropper();

    // this.project.stage.brushLayerSketch.type = 'opacity-brush'
    // this.selectBrush =
  }

  public onSetBrush(brushItem: any) {
    this.setBrushName(brushItem.type);
    this.setBrushSrc(brushItem.srcPreview);
    this.setImageBrushParams();
    // this.selectBrush = brushMenu;
    // this.interfaceTools.showHandMove = false;
    // this.srcToImageBrushParams();
    // this.interfaceTools.onEnableBrush();
    // this.interfaceTools.onHideEyeDropper();
    // this.interfaceTools.onHandMoveStop();
  }

  public restoreState(srcStroke: string) {
    base64ToImageData(srcStroke, (imageData) => putImageData(this.canvasBrush, imageData))
  }

  public download() {
    this.canvasBackgroundCombined();
  }

  cursorNew(cursorX, cursorY) {
    /*  const cursor = document.getElementById('cursor');
      cursor.style.left = `${cursorX}px`;
      cursor.style.top = `${cursorY}px`;*/
  }

  pinchCheck() {
    let isDrawing = false;
    let isPinching = false;
    let isDragging = false;

    const canvas: any = document.getElementById("scroll-container");
    const ctx = canvas.getContext("2d");

// Функция для начала рисования
    /* canvas.addEventListener("mousedown", (e) => {
       if (!isPinching && !isDragging) {
         isDrawing = true;
         ctx.beginPath();
         ctx.moveTo(e.offsetX, e.offsetY);
       }
     });

     canvas.addEventListener("mousemove", (e) => {
       if (isDrawing && !isPinching && !isDragging) {
         ctx.lineTo(e.offsetX, e.offsetY);
         ctx.stroke();
       }
     });

     canvas.addEventListener("mouseup", () => {
       isDrawing = false;
     });*/

// Обработчики для жестов pinch и drag
    canvas.addEventListener("touchstart", (e) => {
      if (e.touches.length === 2) {
        this.isPinching = true;
      } else if (e.touches.length === 1) {
        this.isDragging = true;
      }
      this.isDrawing = false; // Отключаем рисование при pinch или drag
    });

    /* canvas.addEventListener("touchmove", (e) => {
       if (isPinching) {
         // Логика для увеличения/уменьшения зума
       } else if (isDragging) {
         // Логика для перетаскивания
       }
     });*/

    /* canvas.addEventListener("touchend", () => {
       isPinching = false;
       isDragging = false;
       isDrawing = false;
     });*/

  }

  public eventDraw() {
    if (this.isEnableBrush()) {

      const onMouseMove = (event: any) => {
        if (!this.isDrawing) return;
        event.preventDefault();
        const currentPoint = getPosition(event);

        // makeCursor(this.canvasBrush,currentPoint)
        this.dist = distanceBetween(this.lastPoint, currentPoint);
        this.angle = angleBetween(this.lastPoint, currentPoint);
        const sinAngle = Math.sin(this.angle);
        const cosAngle = Math.cos(this.angle);

// Увеличиваем шаг итерации для уменьшения количества точек при большом расстоянии
        const iterationStep = Math.max(1, Math.min(2.5, this.dist / 8));
        for (let i = 0; i < this.dist; i += iterationStep) {
          const x = this.lastPoint.x + sinAngle * i;
          const y = this.lastPoint.y + cosAngle * i;

          // Вызываем switchBrushes с меньшей частотой, пропуская некоторые точки
          this.switchBrushes({
            x,
            y
          });
        }

        this.lastPoint = currentPoint;
        this.cursorNew(currentPoint.x, currentPoint.y)
        this.isMouseMoveCounter += 1;
        this.positionBrush = currentPoint;
        countFilledPixels(this.canvasBrush, currentPoint.x, currentPoint.y, this.filledPixels, this.maxFilledPixels, (pixels: number) => {
          this.filledPixels = pixels
        });

      };

      const onMouseDown = (event: any) => {
        // event.preventDefault();
        this.isDrawing = true;
        this.lastPoint = getPosition(event);
        this.isMouseDownCounter += 1
      };
      const onMouseUp = (event: any) => {
        // event.preventDefault();
        this.isDrawing = false;
        this.onNotClear();

        this.isMouseUpCounter += 1
        console.log(this.isMouseUpCounter, 'isMouseUpCounter')
      };
      this.canvasBrush.addEventListener('touchstart', onMouseDown);
      this.canvasBrush.addEventListener('touchmove', onMouseMove);
      this.canvasBrush.addEventListener('touchend', onMouseUp);
      this.canvasBrush.onmousedown = onMouseDown;
      this.canvasBrush.onmousemove = onMouseMove;
      this.canvasBrush.onmouseup = onMouseUp;
    }
  }

  public canvasBackgroundCombined() {
    const bgCanvas: any = this.canvasBackground;
    const brushCanvas: any = this.canvasBrush;
    const combinedCanvas: any = document.createElement("canvas");
    combinedCanvas.width = bgCanvas.width;
    combinedCanvas.height = bgCanvas.height;
    const combinedCtx: any = getContext(combinedCanvas);

    // Копируем фон на новый canvas
    combinedCtx.drawImage(bgCanvas, 0, 0);

    // Копируем верхний canvas с рисунком на новый canvas
    combinedCtx.drawImage(brushCanvas, 0, 0);

    // Получаем изображение как data URL
    const dataURL = combinedCanvas.toDataURL("image/png");
    downloadImage(dataURL)
  }

  protected clearCanvas() {
    clearCanvas(this.canvasBrush, (canvas: any) => {
      // this.setImageDataToSrcStroke(canvas);
      this.onNotClear();
    });
  }

  protected setImageDataToSrcStroke(canvas: any) {
    let strFromImageData = imageDataToSrcStroke(canvas);
    this.setSrcToStroke(strFromImageData);
  }

  protected setSrcToStroke(srcPreview: any) {
    this.srcStroke = srcPreview;
  }

  protected clickStageEyeDropper() {
    if (this.isEnabledEyeDropper()) {
      let colorHex: any = getPixel(this.context, this.clickPosition);
      this.setBrushColorHex(colorHex);
      this.onDisableEyeDropper();
    }
  }

  protected isDraw() {
    return (this.enableBrush && this.enableErase && this.isDisabledEyeDropper());
  }

  protected events() {
    this.eventEyeDropper();
    if (!this.isPinching) {
      setTimeout(() => {
        this.eventDraw();
      }, 2000)
    }

  }

  protected eventEyeDropper() {
    if (this.isEnabledEyeDropper()) {
      const onMouseClick = (event: any) => {
        // event.preventDefault();
        this.isDrawing = false;
        this.clickPosition = getPosition(event);
        this.clickStageEyeDropper();

        makeCursor(this.canvasBrush, this.clickPosition, {
          colorHex: this.colorBrushHex,
          size: this.size
        });
      };
      this.canvasBrush.addEventListener('click', onMouseClick);
      this.canvasBrush.onpointerdown = onMouseClick;
    }

  }

  protected switchBrushes(position: any = {
    x: 0,
    y: 0
  }) {
    this.context.fillStyle = this.colorBrushHex;
    this.context.strokeStyle = this.colorBrushHex;
    this.context.globalAlpha = this.alpha;
    this.context.globalCompositeOperation = this.globalCompositeOperation;

    switch (this.brushName) {
      case 'brushImage':
        // console.log(this.imgObjectStroke);
        brushImage(this.context, this.imgObjectStroke, position);
        break;
      case 'brushArc':
        // console.log(this.size);
        brushArc(this.context, position, {
          size: this.size,
        });
        break;
    }
  }

  protected setImageBrushParams() {
    this.setBrushMode();

    let params: any = {
      colorHex: this.colorBrushHex,
      height: this.size,
      width: this.size,
      blurFilter: this.blur,
      opacityFilter: this.opacity * 10,
      globalCompositeOperation: this.globalCompositeOperation,
      globalAlpha: this.alpha,
    };
    srcToStroke(this.srcBrushStroke, params, (imgObjectStroke: any) => {
      this.imgObjectStroke = imgObjectStroke;
    });
  }
}
