import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class CdServices {
  public enabledDelete: boolean = false;
  public lock: boolean = false;
  public logined: boolean = false;
  public loginForm: boolean = false;
  public logShow: any = false;
  public lockMove : any = false;
  public log: any = null;
  public onlineStatus: boolean;
  public showActionsSales: boolean = true;
}
