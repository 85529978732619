  <ul class="grid grid-cols-3  gap-1 "
  >
    <li *ngFor="let serviceSite of serviceSites; let last = last;">
      <app-site-service-btn *ngIf="serviceSite.site.organizer.messenges[0].srcPreview"
                            [name] =serviceSite.route
                            [imageSrc] =serviceSite.site.organizer.messenges[0].srcPreview
                            (onSelect)="onSelect.emit(serviceSite)"
                            [showMove]="false"
      />
    </li>
  </ul>

