import {Injectable, Input} from '@angular/core';
import {addToStart} from "../../traits/array-tools";
import { cloneModel } from 'src/app/traits/model-tools';

@Injectable({
  providedIn: 'root',
})


export class KnvColor  {
  public colorsHistory: any = [];
  public colorOrange: any = '#ff930e';
  public colorGray: any = '#686868';
  public colorsFavorites: any = [
    {name: 3, hex: '#4b4a4a'},
    {name: 4, hex: '#000000'},
    {name: 5, hex: '#802f2f'},
    {name: 6, hex: '#808000'},
    {name: 7, hex: '#2e8e2e'},
    {name: 8, hex: '#2e9a9a'},
    {name: 9, hex: '#222282'},
    {name: 10, hex: '#872c87'},
  ];
  public colorsMiddleTailWind: any = [
    {name: 'Slate', hex: '#64748b'},
    {name: 'Gray', hex: '#6b7280'},
    {name: 'Zinc', hex: '#71717a'},
    {name: 'Neutral', hex: '#737373'},
    {name: 'Stone', hex: '#78716c'},
    {name: 'Red', hex: '#ef4444'},
    {name: 'Orange', hex: '#f97316'},
    {name: 'Amber', hex: '#f59e0b'},
    {name: 'Yellow', hex: '#eab308'},
    {name: 'Lime', hex: '#84cc16'},
    {name: 'Green', hex: '#22c55e'},
    {name: 'Emerald', hex: '#10b981'},
    {name: 'Teal', hex: '#14b8a6'},
    {name: 'Cyan', hex: '#06b6d4'},
    {name: 'Sky', hex: '#0ea5e9'},
    {name: 'Blue', hex: '#3b82f6'},
    {name: 'Indigo', hex: '#6366f1'},
    {name: 'Violet', hex: '#8b5cf6'},
    {name: 'Purple', hex: '#a855f7'},
    {name: 'Fuchsia', hex: '#d946ef'},
    {name: 'Pink', hex: '#ec4899'},
    {name: 'Rose', hex: '#f43f5e'},
  ];
  public colorsPastel: any = [
    {hex: '#F7F6CF'},
    {hex: '#B6D8F2'},
    {hex: '#F4CFDF'},
    {hex: '#5784BA'},
    {hex: '#9AC8EB'},
    {hex: '#CCD4BF'},
    {hex: '#E7CBA9'},
    {hex: '#EEBAB2'},
    {hex: '#F5F3E7'},
    {hex: '#F5E2E4'},
    {hex: '#F5BFD2'},
    {hex: '#E5DB9C'},
    {hex: '#D0BCAC'},
    {hex: '#BEB4C5'},
    {hex: '#E6A57E'},
    {hex: '#218B82'},
    {hex: '#9AD9DB'},
    {hex: '#E5DBD9'},
    {hex: '#98D4BB'},
    {hex: '#EB96AA'},
    {hex: '#C6C9D0'},
    {hex: '#C54B6C'},
    {hex: '#E5B3BB'},
    {hex: '#C47482'},
    {hex: '#D5E4C3'},
    {hex: '#F9968B'},
    {hex: '#F27348'},
    {hex: '#26474E'},
    {hex: '#76CDCD'},
    {hex: '#2CCED2'},
    {hex: '#B8E0F6'},
    {hex: '#A4CCE3'},
    {hex: '#37667E'},
    {hex: '#DEC4D6'},
    {hex: '#7B92AA'},
    {hex: '#DDF2F4'},
    {hex: '#84A6D6'},
    {hex: '#4382BB'},
    {hex: '#E4CEE0'},
    {hex: '#A15D98'},
    {hex: '#DC828F'},
    {hex: '#F7CE76'},
    {hex: '#E8D6CF'},
    {hex: '#8C7386'},
    {hex: '#9C9359'},
    {hex: '#F4C815'},
    {hex: '#F9CAD7'},
    {hex: '#A57283'},
    {hex: '#C1D5DE'},
    {hex: '#DEEDE6'},
    {hex: '#E9BBB5'},
    {hex: '#E7CBA9'},
    {hex: '#AAD9CD'},
    {hex: '#E8D595'},
    {hex: '#8DA47E'},
    {hex: '#CAE7E3'},
    {hex: '#B2B2B2'},
    {hex: '#EEB8C5'},
    {hex: '#DCDBD9'},
    {hex: '#FEC7BC'},
    {hex: '#FBECDB'},
    {hex: '#F3CBBD'},
    {hex: '#90CDC3'},
    {hex: '#AF8C72'},
    {hex: '#938F43'},
    {hex: '#B8A390'},
    {hex: '#E6D1D2'},
    {hex: '#DAD5D6'},
    {hex: '#B2B5B9'},
    {hex: '#8FA2A6'},
    {hex: '#8EA4C8'},
    {hex: '#C3B8AA'},
    {hex: '#DEDCE4'},
    {hex: '#DB93A5'},
    {hex: '#C7CDC5'},
    {hex: '#698396'},
    {hex: '#A9C8C0'},
    {hex: '#DBBC8E'},
    {hex: '#AE8A8C'},
    {hex: '#7C98AB'},
    {hex: '#C2D9E1'},
    {hex: '#D29F8C'},
    {hex: '#D9D3D2'},
    {hex: '#81B1CC'},
    {hex: '#FFD9CF'},
    {hex: '#C6AC85'},
    {hex: '#E2E5CB'},
    {hex: '#D9C2BD'},
    {hex: '#A2C4C6'},
    {hex: '#82B2B8'},
    {hex: '#874741'},
    {hex: '#CA9C95'},
    {hex: '#40393E'},
    {hex: '#E5E4E5'},
    {hex: '#897C87'},
    {hex: '#46302B'},
    {hex: '#76504E'},
    {hex: '#D3CCCA'},
    {hex: '#A37E7E'},
    {hex: '#86736C'},
  ];

  public hideChromaBtn(){
    this.showChroma = false;
    this.showIro = true;
  }
  closeChroma(){
    this.showChroma =false;
  }
  openChroma(){

  }
  closeColorLib(){
    this.showColorLib =false;
  }

  openColorLib(){
    this.showColorLib =true;

  }
  public showChromaBtn(){
    this.showChroma = true;
    this.showIro = false;
  }
  public heightLeftBlock: any = 240;
  public showChroma: any = true;
  public showColorLib: any = false;
  public showIro: any = true;
  public colorsClientLib: any = [{
    hex: '#fa9a03',
    rgba: 'rgba(255, 228, 205, 1)',
  }];
  public colorsSketch: any = [
    {name: 4, hex: '#000000'},
    {name: 8, hex: '#959595'},
    {name: 5, hex: '#7b4343'},
    {name: 7, hex: '#498d49'},
    {name: 8, hex: '#379a9a'},
  ];
  public selectColor: any = {
    hex: '#fa9a03',
    rgba: 'rgba(255, 228, 205, 1)',
  };
  public selectHistoryColor: any = {
    hex: '#fa9a03',
    rgba: 'rgba(255, 228, 205, 1)',
  };
  public selectChromaColor: any = {
    hex: '#fa9a03',
    rgba: 'rgba(255, 228, 205, 1)',
  };
  public selectIroColor: any = {
    hex: '#fa9a03',
    rgba: 'rgba(255, 228, 205, 1)',
  };
  public selectHarmonyColor: any = {
    hex: '#fa9a03',
    rgba: 'rgba(255, 228, 205, 1)',
  };
  public selectRoundPicker: any = {
    hex: '#fa9a03',
    rgba: 'rgba(255, 228, 205, 1)',
  };
  public selectSize: any = {
    value: 20,
  };
  public previewColor: any = {
    hex: '#fa9a03',
    rgba: 'rgba(255, 228, 205, 1)',
  };
  public triggerColorsInOtherTools(colorHex: any = 'red',
                                   historyEnable: any = false,
                                   chromaEnable: any = false,
                                   harmonyEnable: any = false,
                                   iroEnable: any = false,
  ) {
    chromaEnable ? this.selectChromaColor.hex = colorHex : null;
    harmonyEnable ? this.selectHarmonyColor.hex = colorHex : null;
    iroEnable ? this.selectIroColor.hex = colorHex : null;
    historyEnable ? this.selectHistoryColor.hex = colorHex : null;
    // chromaEnable?this.selectRoundPicker.hex = colorHex:null;
    /*       konvaTools.selectChromaColor.hex =$event.hex;
       konvaTools.selectHistoryColor.hex = $event.hex;
       konvaTools.selectIroColor.hex = $event.hex;
       konvaTools.selectRoundPicker.hex = $event.hex;*/
  }

  public historyColorTrigger(colorHex: any = 'red') {
    this.selectHistoryColor.hex = colorHex;
    this.selectChromaColor.hex = colorHex;
    this.selectIroColor.hex = colorHex;
    this.selectRoundPicker.hex = colorHex;
  }

  public addToColorsFavorites($event: any) {
    let clone = cloneModel($event);
    addToStart(clone, this.colorsFavorites);
    // this.alert.custom('save to favourites...');

  }
}
