<div *ngIf="showResult" draggable="false"   class="chessboard-bg" [style.zoom]="scaleCanvas" >
        <img [src]="imgResult" />
      </div>

<app-btn-download *ngIf="showDownload"
                  [srcImage]="imgResult"
                  [disabled]="false"
></app-btn-download>

<!--  <a [href]="imgResult" draggable="false">
    <img [src]="imgResult" id="zoom-trigger" draggable="false" class="w-64 h-64" alt="">
  </a>-->

<!--<div class="container">
  <div class="row mt-3">
    <div class="col-sm-6">
    </div>
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-6">
          <img [src]="imageSrc" id="raw-img" class="d-block w-100">
        </div>
        <div class="col-sm-6" id="result">
        </div>
      </div>
    </div>
  </div>
</div>-->
