import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {
  NgFor,
  NgIf
} from '@angular/common';
import {EditTextRowComponent} from '../edit-text-row/edit-text-row.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {modelNew} from '../../../traits/model-tools';
import {UploaderPhotoComponent} from '../uploader-photo/uploader-photo.component';
import {SortablejsModule} from "@maksim_m/ngx-sortablejs";
import {SortableOptions} from "sortablejs";
import {
  cropIcon,
  deleteIcon,
  editIcon,
  favoriteIcon,
  imagePanoramaIcon,
  saveIcon,
  tagIcon
} from "../../../traits/icons-tools";
import {
  addToStart,
  deleteFromArray
} from "../../../traits/array-tools";
import {
  base64ImageToSquare,
  checkSrc,
  isBase64
} from 'src/app/traits/image-tools';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {AvatarModule} from "primeng/avatar";
import {CropRigionComponent} from "../../crop-rigion/crop-rigion.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {ServerImageServices} from "../../../services/servers/server-image.services";
import {CdServices} from "../../../services/common/cd.services";
import {EditTextRowService} from "../../../services/common/edit-text-row.service";
import {ToggleButtonModule} from "primeng/togglebutton";

@Component({
  selector: 'app-list-upload',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    EditTextRowComponent,
    ScrollPanelModule,
    UploaderPhotoComponent,
    SortablejsModule,
    OverlayPanelModule,
    AvatarModule,
    CropRigionComponent,
    SidebarComponent,
    ToggleButtonModule,

  ],
  templateUrl: './list-upload.component.html',
})
export class ListUploadComponent {
  @Input() models: any = [];
  @Input() view: any = 'grid';
  @Input() cols: any = 3;
  @Input() showCounter: any = true;
  @Input() heightItem: any = 50;
  @Input() widthItem: any = 50;
  @Input() heightList: any = 150;
  @Input() showUpload: any = true;
  @Input() showBatch: any = true;
  @Input() showImage: any = true;
  @Input() showList: any = true;
  @Input() itemSelect: any;
  @Input() showAdd: any = true;
  @Input() showEdit: any = false;
  @Input() showName: any = true;
  @Input() showMore: any = false;
  @Input() showFavorite: any = true;
  @Input() showSort: any = false;
  @Input() showCheck: any = false;
  @Input() showShow: any = false;
  @Input() showLock: any = false;
  @Input() showDelete: any = false;
  @Input() showEyeDropper: any = false;
  @Input() showDownload: any = false;
  @Input() showTools: any = true;
  @Input() showDeleteServerImage: any = false;
  @Input() showToJPG: any = false;
  @Input() sizeView: any = 'size';

  @Output() onAdd = new EventEmitter();
  @Output() onClickUpload = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() onColor = new EventEmitter();
  @Output() onMore = new EventEmitter();
  @Output() onSort = new EventEmitter();
  @Output() onCheck = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onLock = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onFavorite = new EventEmitter();
  @Output() onUpload = new EventEmitter();
  @Output() onUploadedFinish = new EventEmitter();
  @Output() onJPG = new EventEmitter();
  @Output() onSetPreview = new EventEmitter();
  public sortableOptions:SortableOptions = {
    animation: 150,
    group: 'list',
    handle: '.handle',
    onUpdate: (event: any) => {
      console.log(event);
      this.onSort.emit()
    }
  }
  // preloadUpload:any =false

  constructor(public cd:CdServices,
              public serverImage: ServerImageServices,
              public textServices: EditTextRowService,
              ) {


  }

  ngOnInit() {

  }

  public addPhotos(base64: string) {
    let photo = modelNew()
    photo.srcPreview = base64;
    addToStart(photo, this.models);
  }
  public isBase64(model: any): boolean {
    return model.srcPreview && checkSrc(model.srcPreview).name === 'BASE64';
  }
  protected readonly deleteIcon = deleteIcon;
  protected readonly favoriteIcon = favoriteIcon;
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly editIcon = editIcon;
  protected readonly imagePanoramaIcon = imagePanoramaIcon;
  protected readonly cropIcon = cropIcon;
  showCropRigion: boolean =false;
  public addCropImage(base64: string, models: any[]) {
    let modelCrop: any = modelNew()
    modelCrop.srcPreview = base64;
    addToStart(modelCrop, models)
  }
  public baseToSrc(model: any, sizeMode: any = 'middle') {
    base64ImageToSquare(model.srcPreview,'dark',false)
      .then((squareBase64Image) => {
        model.srcPreview = squareBase64Image;
        this.serverImage.toImageJpg(model, sizeMode);
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });


  }

  public itemDelete(model: any, models: any) {
    deleteFromArray(model, models);
    this.textServices.deleteImageFromServer(model);
  }

  protected readonly tagIcon = tagIcon;

  public setPreviewBtn(model: any) {
    this.onAdd.emit(model);
    this.onSort.emit(model)
  }

  protected readonly saveIcon = saveIcon;
}
