<div  *ngIf="showHistoryBar">
  <div class="absolute z-20 -top-[25px] flex justify-start ">
    <app-tool-history-colors
      slot="3"
      [view]="'grid'"
      [selectHistoryColor]=color.selectHistoryColor
      [heightList]="30"
      [widthList]="innerWidth"
      [widthItem]="20"
      [heightItem]="30"
      [change]=" color.selectHistoryColor.hex"
      [sliceColors]="100"
      (onColor)="
                 drawCanvas.setBrushColorHex($event);
                color.triggerColorsInOtherTools($event,
                false,
                true,
                true,
                true
                );
                this.onColor.emit($event)

    "

    >
    </app-tool-history-colors>
    <!--  (onDblclick)="  konvaTools.rectangle.onSetBackgroundColor($event.hex,true)"
      (onPress)=" konvaTools.rectangle.onSetBackgroundColor($event.hex,true)"-->
  </div>

</div>
