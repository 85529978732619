import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {SidebarComponent} from "../../../common/sidebar/sidebar.component";

import {ToolChromaColorsComponent} from "../../color/tool-chroma-colors/tool-chroma-colors.component";
import {ToolColorHarmonyComponent} from "../../color/tool-color-harmony/tool-color-harmony.component";
import {ToolIroColorsZoomMinusComponent} from "../../color/tool-iro-colors-zoom-minus/tool-iro-colors-zoom-minus.component";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {EyeDropperComponent} from "../../../eye-dropper/eye-dropper.component";
import { InteractTools } from 'src/app/services/common/interact-tools';

@Component({
  selector: 'app-pinch-reference',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    EyeDropperComponent,
  ],
  templateUrl: './pinch-reference.component.html',

})
export class PinchReferenceComponent implements OnInit, AfterViewInit {
  // @ts-ignore
  @ViewChild('gestureArea') gestureArea: ElementRef;
  // @ts-ignore
  @ViewChild('scaleElement') scaleElement: ElementRef;
  @Input() public imageSrc: string ='assets/overlay-painting.jpg';
  @Input()public visible: boolean = true;
  @Input() public opacity: any = 100;
  @Input() public overlayDraw: boolean =false;
  @Input() public lockMove: boolean =false;
  @Input() public enableDrag: any = true;

  @Input() public width: number =300;
  @Input() public height: number =300;

  @Output() onClick = new EventEmitter();
  @Output() onColor = new EventEmitter();
  constructor(
  ) {

  }

  public interactRaw() {
    let interactTools = new InteractTools(
    );
    interactTools.enableDrag = this.enableDrag;
    interactTools.multiTouch(
      this.gestureArea.nativeElement,
      this.scaleElement.nativeElement,
    );
    interactTools.draggable(
      this.gestureArea.nativeElement,
    );
  }

  ngOnInit() {

  }
  ngAfterViewInit() {
this.interactRaw();
  }

}
