<!--<img *ngIf="float"
     [style.width.px]="width"
     [style.height.px]="height"
     [src]="this.croppedImage" alt="crop">

<app-tool-sidebar [show]=" showCropsItems"
                  (onSave)="showCropsItems =false"
                  [width]="100"
                  [height]="50"
                  [ext]="'%'"
                  [position]="'top'"
                  [showClose]="true"
                  [modal]="false"
>
  <app-tool-scroller-horizontal
    *ngIf="crops"
    [widthRow]="300"
    [heightList]="150"
    [models]="crops"
    [heightItem]="75"
    [widthItem]="50"
    [showAdd]="true"
    [showColors]="false"
    [showTools]="true"
    (onAdd)="cd.saveToDevice($event,'srcPreview')"
  >
  </app-tool-scroller-horizontal>
</app-tool-sidebar>-->
<div *ngIf="!float" class="relative"
     [style.width.px]="width"
     [style.height.px]="height"
>
  <!-- [cropperStaticHeight]= "100"
    [cropperStaticWidth]= "125"

    [resizeToWidth]="256"
    [cropperMinWidth]="128"
        [roundCropper]="false"
    [onlyScaleDown]="true"
    [aspectRatio]="4 / 3"

    -->

  <image-cropper
    class="custom-image-cropper"
    [output]="'base64'"
    [imageURL]="src"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="containWithinAspectRatio"
    [containWithinAspectRatio]="containWithinAspectRatio"
    [cropper]="coordinates"
    [transform]="transform"
    [canvasRotation]="canvasRotation"
    [alignImage]="'left'"
    [style.display]="showCropper ? null : 'none'"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>

<!--  <div *ngIf="showClose" class="absolute top-0 left-0 z-20 ">
    <button (click)="onClose.emit(); cropRigion.onClose()"
            class=" bg-zinc-700 text-zinc-200 ">
      <i [class]="theme.icon.closeSquare"></i>
    </button>
  </div>-->
<!--  <div *ngIf="showClose" class="absolute top-0 right-0 z-20 ">
    <button (click)="onAdd.emit(); cropRigion.addCrop(cropRigion.cropResult)"
            class=" bg-zinc-700 text-zinc-200  ">
      <i [class]="theme.icon.add"></i>
    </button>
  </div>-->


  <div class="d-flex flex-column z-10 block text-[white] w-[30px]  h-[100px]  absolute top-[50px] left-[50px] rounded-lg">
 <!--   <ng-container *ngIf="showClose">
      <button
        class="btn-custom-big" (click)="onClose.emit(); cropRigion.onClose()"
      >
        <i [class]="theme.icon.close"></i>
      </button>
    </ng-container>-->
    <ng-container *ngIf="showFlip">
      <button class="btn-custom" (click)="flipHorizontal()">
        <i class="far fa-arrow-alt-circle-left"></i>
      </button>
      <button class="btn-custom" (click)="flipVertical()">
        <i class="far fa-arrow-alt-circle-up"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="showZoom">

      <button class="btn-custom" (click)="zoomOut()">
        <i class="fas fa-search-minus"></i>
      </button>
      <button class="btn-custom" (click)="zoomIn()">
        <i class="fas fa-search-plus"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="showRotate">

      <button class="btn-custom" (click)="rotateLeft()">
        <i class="fas fa-undo"></i>
      </button>
      <button class="btn-custom" (click)="rotateRight()">
        <i class="fas fa-redo"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="showReset">
      <button class="btn-custom" (click)="resetImage()">
        <i class="fas fa-ban"></i>
      </button>
    </ng-container>




    <!--<button class="btn-custom-big absolute bottom-10" (click)="addToCrops()">
      <i [class]="theme.icon.add"></i>
    </button>-->
    <ng-container *ngIf="showPressetsCrop">
      <ng-container *ngFor="let  cropParams  of cropsParams;">
        <button
          (click)="setFrameSize(cropParams.height,cropParams.width,cropParams.y,cropParams.x)"
          class="btn-custom"
        >
          <i [class]="cropParams.icon"></i>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showDownload">
      <button class="btn-custom-big" (click)="download()">
<!--        <i [class]="theme.icon.download"></i>-->
      </button>
    </ng-container>
    <ng-container *ngIf="showAdd">
      <!--cd.showCrop = false; onSave.emit({srcPreview:this.croppedImage})-->
      <button
        class="btn-custom-big" (click)="onAdd.emit(this.croppedImage);alert('add')"
      >
<!--        <i [class]="theme.icon.add"></i>-->
      </button>
    </ng-container>
    <button *ngIf="showAspectRatio"
      class="btn-custom-big" (click)="containWithinAspectRatio = !containWithinAspectRatio"
    >
<!--      <i [class]="theme.icon.sort"></i>-->
    </button>
    <!-- <button class="btn-custom" (click)="cd.showRotatePresets = true"><i
       class="fas fa-search-plus"></i></button>
     <button class="btn-custom" (click)="cd.showSizePresets = true"><i
       class="fas fa-search-plus"></i></button>-->

    <!--  <button class="btn-custom"  (click)="toggleContainWithinAspectRatio()">  <i  class="fas fa-percentage"></i></button>-->
    <!--  <app-rotation [item]="model" (eventEmit)="rotation = $event; updateRotation()"></app-rotation>-->
    <!--  <input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" />-->
  </div>

</div>


