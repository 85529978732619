export function confettiAnimation(typeSwitch:string = 'base'){
  // @ts-ignore
  let params:any
  switch ('base') {
    case "base":
      params = {
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      }
      break;
  }
  // @ts-ignore
  confetti(params);
}

