import {
  Component,
  EventEmitter,
  Input,
  Output,

} from '@angular/core';
import {
  NgFor,
  NgIf
} from '@angular/common';
import {TabViewModule} from "primeng/tabview";
import {EditTextRowComponent} from "../edit-text-row/edit-text-row.component";
import {BoxUploadComponent} from "../../box/box-upload/box-upload.component";
import {deleteIcon} from "../../../traits/icons-tools";
import {
  addToEnd,
  addToEndNotExits,
  deleteFromArray
} from "../../../traits/array-tools";
import {EditArrayComponent} from "../edit-array/edit-array.component";
import {SiteServiceBtnComponent} from "../site-service-btn/site-service-btn.component";
import {
  model,
  modelStep
} from "../../../traits/model-tools";
@Component({
  selector: 'app-edit-children',
  templateUrl: './edit-children.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    TabViewModule,
    EditTextRowComponent,
    BoxUploadComponent,
    EditArrayComponent,
    SiteServiceBtnComponent,
  ]
})
export class EditChildrenComponent   {

@Input() public card:any;
@Input() public cards:any[];
  @Output() onSave:EventEmitter<any> = new EventEmitter;
  @Input() textEditors: any[] = [
    {
      name: 'name',
      type: 'text',
      similar: []
    },
    {
      name: 'desc',
      type: 'text',
      similar: []
    },
    {
      name: 'srcIcon',
      type: 'srcIcon',
      similar: []
    },

    {
      name: 'srcPreview',
      type: 'srcPreview',
      similar: []
    },
    {
      name: 'type',
      type: 'text',
      similar: []
    },
    {
      name: 'status',
      type: 'text',
      similar: []
    },
    {
      name: 'costDiscount',
      type: 'text',
      similar: []
    },
    {
      name: 'action',
      type: 'text',
      similar: []
    },

    {
      name: 'color',
      type: 'color',
      similar: []
    },
    {
      name: 'cat',
      type: 'text',
      similar: []
    },
    {
      name: 'duration',
      type: 'text',
      similar: []
    },
    {
      name: 'level',
      type: 'text',
      similar: []
    },
    {
      name: 'additionally',
      type: 'text',
      similar: []
    },
    {
      name: 'cost',
      type: 'text',
      similar: []
    },
    {
      name: 'info',
      type: 'text',
      similar: []
    },
    {
      name: 'srcUrl',
      type: 'text',
      similar: []
    },
    {
      name: 'srcFRAME',
      type: 'text',
      similar: []
    },
    {
      name: 'srcFile',
      type: 'text',
      similar: []
    },
    {
      name: 'routerLink',
      type: 'text',
      similar: []
    },
    {
      name: 'id',
      type: 'text',
      similar: []
    },    {
      name: 'nameBox',
      type: 'text',
      similar: []
    },    {
      name: 'duration',
      type: 'text',
      similar: []
    },    {
      name: 'pathBox',
      type: 'text',
      similar: []
    },

  ];
  protected readonly deleteIcon = deleteIcon;
  @Output() onEdit = new EventEmitter<any>();
  public delete(model: any, cards: any) {
    deleteFromArray(model, cards);
  }
  public addToFavorite(srcPreview: any) {
    // this.card.favorites=  [];
     let favorModel:any = modelStep();
     favorModel.srcPreview = srcPreview;
    addToEndNotExits(favorModel,this.card.favorites)
    console.log(this.card.favorites)

    // this.saveSite()
  }
  saveSite() {
    this.onSave.emit()
  }
}
