
<!--<div class="bg-red-700 text-green-50">{{drawCanvas.isPinching}}</div>-->
<div


  id="scroll-container"
  [style.width.px]="width"
  [style.height.px]="height"
  class="container text-center z-0 p-0 m-0"
>
  <div #gestureArea class="p-0 m-0"
       [class.drag-handle]="drawCanvas.isDraggable"
  >

    <div #scaleElement
         [style.width.px]="width"
         [style.height.px]="height"
         style="position: relative;"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
