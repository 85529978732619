<canvas
  id="canvas-background"
  [style.width.px]="width"
  [style.height.px]="height"
></canvas>
      <canvas   class="z-20"
                id="canvas-brush"
                [style.width.px]="width"
                [style.height.px]="height"
                style="position: absolute; top: 0; left: 0;"
      ></canvas>

